import React, { Component } from 'react'
import InputWrapper from './InputWrapper'
import styles from './Input.module.scss'

export interface DefaultInputProps<T> {
    propertyKey: string
    label?: string
    register: any
    errors: any
    value?: T
    defaultValue?: T
    placeholder?: string
    onClick?(event: any)
    className?: string
    alignCenter?: boolean
}

interface Props extends DefaultInputProps<string> { }
interface State {

}

export default class HookedTextArea extends Component<Props, State> {
    render() {

        const currentError = this.props.errors[this.props.propertyKey]

        return (
            <InputWrapper<string> {...this.props}>
                <textarea
                    className={`${styles.input} ${currentError ? styles.input__error : ''}`}
                    value={this.props.value}
                    defaultValue={this.props.defaultValue}
                    placeholder={this.props.placeholder}
                    ref={this.props.register}
                    name={this.props.propertyKey}
                ></textarea>
            </InputWrapper>
        )
    }
}
