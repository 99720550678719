import React, { Component, CSSProperties } from 'react'
import styles from './GridBase.module.scss'
import Icon from '../Icon/Icon'
import Dropdown, { RowActionItem } from '../Dropdown/Dropdown'
import { SortEnumType } from '../../../generated/graphql'
import search from '../../../icons/search.svg'
import { Link } from 'react-router-dom'

export interface ColDef<T> {
    sortable?: boolean
    searchable?: boolean
    headerName: string
    field?: string
    styles?: CSSProperties
    dataTransform?(entity: T): string
}

export interface RowAction {
    icon?: string
    items?: RowActionItem[]
    customComponent?(entity: any): JSX.Element
}

export interface GridSort {
    field: string
    direction: SortEnumType
}

export interface StyleRule<T> {
    ruleFn(obj: T, columnDefinition?: ColDef<T>): boolean
    cssProperties: CSSProperties
}

export interface GridOptions<T> {
    columnDefs: ColDef<T>[]
    rowData: T[]
    rowActions?: RowAction[]
    cellStyleRules?: StyleRule<T>[]

    dataKeyColumn?: string
    linkToPath?: string

    clickAction?(key: number)

    currentSort?: any
    sortAction?(sortObject: any)

    currentFilter?: any
    filterAction?(filterObject: any)
    isLoading: boolean
    isErrored: boolean

    responsiveLarge?: boolean
    size?: GridSize

    selectableRows?: boolean
    updateSelectedRows?(selectedKeys: number[]): any
}

export enum GridSize {
    small = "small"
}

interface Props<T> {
    gridOptions: GridOptions<T>
}

interface SortState {
    field: string
    direction: SortEnumType
}

interface State {
    sort: SortState
    checkboxes: boolean[]
    headerCheckboxChecked?: boolean
}

export default class GridBase<T> extends Component<Props<T>, State> {
    constructor(props) {
        super(props)

        this.state = {
            checkboxes: [],
            sort: null,
            headerCheckboxChecked: false
        }
    }

    componentDidMount() {
        if (this.props.gridOptions.selectableRows) {
            this.setState({ checkboxes: this.props.gridOptions.rowData.map(d => false) })
        }
    }

    componentDidUpdate(prevProps: Props<T>) {
        var newDataKeys = this.props.gridOptions.rowData.map(d => d[this.props.gridOptions.dataKeyColumn])
        var oldDataKeys = prevProps.gridOptions.rowData.map(d => d[this.props.gridOptions.dataKeyColumn])

        var sameOrders = (JSON.stringify(newDataKeys) === JSON.stringify(oldDataKeys))
        if (this.props.gridOptions.selectableRows && !sameOrders) {
            this.setState((state, props) => {
                return { checkboxes: props.gridOptions.rowData.map(d => false) }
            }, this.updateHeaderCheckbox)
        }
    }

    toggleCheckAll(check?: boolean) {
        const allChecked = this.state.checkboxes.filter(c => c === false).length === 0

        this.setState((state, props) => {
            return {
                checkboxes: props.gridOptions.rowData.map(d => !allChecked),
                headerCheckboxChecked: !state.headerCheckboxChecked
            }
        })

        this.updateCheckedItems(new Array(this.props.gridOptions.rowData.length).fill(!allChecked))
    }

    getCurrentSortField(): string {
        return this.props.gridOptions.currentSort && Object.keys(this.props.gridOptions.currentSort)
            .map(k => !!this.props.gridOptions.currentSort[k] && k)
            .filter(v => !!v)[0]

    }

    getCurrentSortDirection(): SortEnumType {
        return Object.keys(this.props.gridOptions.currentSort)
            .map(k => this.props.gridOptions.currentSort[k])
            .filter(v => !!v)[0]
    }

    handleSort(field: string) {
        const currentSortDirection = this.getCurrentSortDirection()
        const currentSortField = this.getCurrentSortField()

        const isSameField = currentSortField === field

        const newSort = isSameField ?
            (currentSortDirection === SortEnumType.Asc ? SortEnumType.Desc : SortEnumType.Asc) :
            SortEnumType.Asc

        this.props.gridOptions.sortAction({ [field]: newSort })
    }

    handleSearch(field: string, value: string): boolean {
        const key = `${field}`
        this.props.gridOptions.filterAction({ [key]: { contains: value } })
        return true
    }

    searchHeader(column: ColDef<T>) {

        const overridenTextColor = column.styles && column.styles.color
        const colorStyle: CSSProperties = !!overridenTextColor ? { filter: "invert(1)" } : {}

        return (
            <figure tabIndex={-1} className={styles.search__container} onClick={(evt) => evt.currentTarget.querySelector('input').focus()}>
                <img style={colorStyle} className={styles.search__icon} src={search} alt="Search" />
                <div className={styles.search__dropdown}>
                    <input
                        className={styles.search__input}
                        onKeyDown={(evt) => evt.key === "Enter" && this.handleSearch(column.field, evt.currentTarget.value) && evt.currentTarget.blur()}
                    />
                </div>
            </figure>
        )
    }

    buildColumnHeader(column: ColDef<T>) {
        const isCurrentSort = column.field === this.getCurrentSortField()


        return (
            <th style={column.styles} className={styles.header} key={column.headerName}>

                <div className={styles.header__wrapper}>
                    <span
                        onClick={() => column.sortable && this.handleSort(column.field)}
                        key={column.headerName}
                        className={`${column.sortable && styles.header__sortable} ${styles.header__text} ${column.searchable && styles.header__searchable}`}
                    >
                        {column.headerName}
                    </span>

                    {column.sortable &&
                        <span data-sort-direction={isCurrentSort ? this.getCurrentSortDirection() : undefined}
                            className={styles.header__sortIndicator}>
                        </span>}

                    {column.searchable && this.searchHeader(column)}
                </div>
            </th>
        )
    }

    renderIcon(rowData: any, rowAction: RowAction, rowKey: number, actionKey: number) {
        const activatedStyles = this.props.gridOptions.cellStyleRules &&
            this.calculateStyles(rowData, null, this.props.gridOptions.cellStyleRules)

        return <td style={activatedStyles} key={`action-item-${rowKey}-${actionKey}`}>

            {rowAction.customComponent ? rowAction.customComponent(rowData) :
                <Icon iconPath={rowAction.icon}>
                    <Dropdown rowItems={rowAction.items} keyPrefix={`${rowKey}-${actionKey}`} callingData={rowData} />
                </Icon>
            }

        </td>
    }

    renderDataCell(rowData: T, columnData: ColDef<T>, rowIndex: number, columnIndex: number) {

        const { linkToPath, dataKeyColumn } = this.props.gridOptions
        const hasLink = !!linkToPath && !!dataKeyColumn

        // Use manual data transform to display data
        const textContent = columnData.dataTransform ?
            columnData.dataTransform(rowData) :
            columnData.field ? columnData.field.split(".").reduce((a, b) => a[b], rowData) :
                ""

        const activatedStyles = this.props.gridOptions.cellStyleRules &&
            this.calculateStyles(rowData, columnData, this.props.gridOptions.cellStyleRules)

        const finalStyles = { ...columnData.styles, ...activatedStyles }


        const innerContent = !hasLink ?
            <span title={textContent} style={finalStyles} className={styles.textHolder} >{textContent}</span> :
            <Link title={textContent}
                style={finalStyles}
                to={`${linkToPath.replace(":key", rowData[dataKeyColumn])}`}
                className={styles.linkHolder}>{textContent}</Link>


        return <td data-label={columnData.headerName} key={`col-${rowIndex}-${columnIndex}`} style={finalStyles}>{innerContent}</td>
    }

    renderRow(line: any, rowIndex: number) {
        const { clickAction, linkToPath, rowActions } = this.props.gridOptions
        const hasClick = !!clickAction

        return <tr className={`${styles.row} ${(linkToPath || clickAction) && styles.row__linked} ${rowActions && styles.row__actionable}`} onClick={_ => hasClick ? clickAction(line) : undefined} key={`row-${rowIndex}`}>
            {this.props.gridOptions.selectableRows && <td>{this.renderCheckbox(rowIndex)}</td>}
            {this.props.gridOptions.columnDefs.map((d, j) => this.renderDataCell(line, d, rowIndex, j))}
            {this.props.gridOptions.rowActions && rowActions.map((rowAction, j) => this.renderIcon(line, rowAction, rowIndex, j))}
        </tr>
    }

    calculateStyles(rowData: any, columnDefinition: ColDef<T>, styleRules: StyleRule<T>[]): CSSProperties {
        return styleRules.reduce((total: CSSProperties, rule) => {
            if (rule.ruleFn(rowData, columnDefinition)) { return { ...total, ...rule.cssProperties } }
            return total
        }, {})
    }

    renderCheckbox = (index) => {
        let currentlyChecked = !!this.state.checkboxes[index]

        return <input
            type="checkbox"
            checked={currentlyChecked}
            onChange={this.toggleCheckbox.bind(this, index)}
        />
    }

    toggleCheckbox = (index) => {
        const { checkboxes } = this.state

        checkboxes[index] = !checkboxes[index]

        this.setState({
            checkboxes
        })

        this.updateHeaderCheckbox()

        this.updateCheckedItems(checkboxes)
    }

    updateHeaderCheckbox() {
        const unchecked = this.state.checkboxes.filter(c => c === false)

        if (unchecked.length === 0 && this.state.checkboxes.length !== 0) {
            this.setState({
                headerCheckboxChecked: true
            })
        }
        else {
            this.setState({
                headerCheckboxChecked: false
            })
        }
    }

    updateCheckedItems(currentlyChecked: boolean[]) {
        this.props.gridOptions.updateSelectedRows(
            this.props.gridOptions.rowData.filter((d, index) => {
                return currentlyChecked[index]
            }
            ).map(r => r[this.props.gridOptions.dataKeyColumn])
        )
    }

    render() {
        return (
            <div className={` ${styles.gridBase__tiled} ${this.props.gridOptions.size === GridSize.small ? styles.gridBase__smallText : ''}`}>
                <table className={`${styles.gridBase} ${this.props.gridOptions.responsiveLarge ? styles.responsiveLarge : ''}`}>
                    <thead>
                        <tr>
                            {this.props.gridOptions.selectableRows && <th><input type="checkbox" onChange={this.toggleCheckAll.bind(this)} checked={this.state.headerCheckboxChecked} /></th>}
                            {this.props.gridOptions.columnDefs.map(d => this.buildColumnHeader(d))}
                            {this.props.gridOptions.rowActions && this.props.gridOptions.rowActions.map((a, i) => <th className={a.icon && styles.header__iconColumn} key={`action-col-${i}`}></th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.gridOptions.rowData.map((line, i) => this.renderRow(line, i))}
                    </tbody>
                </table>
            </div>
        )
    }
}