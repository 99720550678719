import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type BasisType = {
  __typename?: 'BasisType';
  basisTypeKey: Scalars['Int'];
  basisTypePrices?: Maybe<Array<Maybe<BasisTypePrice>>>;
  currentPrice?: Maybe<BasisTypePrice>;
  description?: Maybe<Scalars['String']>;
  indexContracts?: Maybe<Array<Maybe<IndexContract>>>;
  isArchived: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  swapDeals?: Maybe<Array<Maybe<SwapDeal>>>;
};


export type BasisTypeBasisTypePricesArgs = {
  order?: InputMaybe<Array<BasisTypePriceSortInput>>;
  where?: InputMaybe<BasisTypePriceFilterInput>;
};

export type BasisTypeFilterInput = {
  and?: InputMaybe<Array<BasisTypeFilterInput>>;
  basisTypeKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  basisTypePrices?: InputMaybe<ListFilterInputTypeOfBasisTypePriceFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  indexContracts?: InputMaybe<ListFilterInputTypeOfIndexContractFilterInput>;
  isArchived?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BasisTypeFilterInput>>;
  swapDeals?: InputMaybe<ListFilterInputTypeOfSwapDealFilterInput>;
};

export type BasisTypePrice = {
  __typename?: 'BasisTypePrice';
  basisType?: Maybe<BasisType>;
  basisTypeKey: Scalars['Int'];
  basisTypePriceKey: Scalars['Int'];
  effectiveDate: Scalars['DateTime'];
  price: Scalars['Float'];
};

export type BasisTypePriceFilterInput = {
  and?: InputMaybe<Array<BasisTypePriceFilterInput>>;
  basisType?: InputMaybe<BasisTypeFilterInput>;
  basisTypeKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  basisTypePriceKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  effectiveDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<BasisTypePriceFilterInput>>;
  price?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type BasisTypePriceSortInput = {
  basisType?: InputMaybe<BasisTypeSortInput>;
  basisTypeKey?: InputMaybe<SortEnumType>;
  basisTypePriceKey?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
};

export type BasisTypeSortInput = {
  basisTypeKey?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isArchived?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export enum BusinessType {
  Agriculture = 'AGRICULTURE',
  EndUser = 'END_USER',
  Industry = 'INDUSTRY',
  Retailer = 'RETAILER',
  Wholesaler = 'WHOLESALER'
}

export type BusinessTypeOperationFilterInput = {
  eq?: InputMaybe<BusinessType>;
  in?: InputMaybe<Array<BusinessType>>;
  neq?: InputMaybe<BusinessType>;
  nin?: InputMaybe<Array<BusinessType>>;
};

export type Carrier = {
  __typename?: 'Carrier';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  carrierKey: Scalars['Int'];
  carrierPointToPointRateDateSpans?: Maybe<Array<Maybe<CarrierPointToPointRateDateSpan>>>;
  carrierRates?: Maybe<Array<Maybe<CarrierRate>>>;
  carrierType: CarrierType;
  city?: Maybe<Scalars['String']>;
  contacts: Array<Contact>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  fuelSurcharges?: Maybe<Array<Maybe<FuelSurcharge>>>;
  isArchived: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  orderTripNumberFillers?: Maybe<Array<Maybe<OrderTripNumberFiller>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  phone?: Maybe<Scalars['String']>;
  primaryLocations?: Maybe<Array<Maybe<Location>>>;
  secondaryLocations?: Maybe<Array<Maybe<Location>>>;
  state?: Maybe<Scalars['String']>;
  userCarriers?: Maybe<Array<Maybe<UserCarrier>>>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CarrierFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CarrierFilterInput>>;
  carrierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  carrierPointToPointRateDateSpans?: InputMaybe<ListFilterInputTypeOfCarrierPointToPointRateDateSpanFilterInput>;
  carrierRates?: InputMaybe<ListFilterInputTypeOfCarrierRateFilterInput>;
  carrierType?: InputMaybe<CarrierTypeOperationFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  contacts?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  fuelSurcharges?: InputMaybe<ListFilterInputTypeOfFuelSurchargeFilterInput>;
  isArchived?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CarrierFilterInput>>;
  orderTripNumberFillers?: InputMaybe<ListFilterInputTypeOfOrderTripNumberFillerFilterInput>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  primaryLocations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  secondaryLocations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  userCarriers?: InputMaybe<ListFilterInputTypeOfUserCarrierFilterInput>;
  zipCode?: InputMaybe<StringOperationFilterInput>;
};

export type CarrierPointToPointRate = {
  __typename?: 'CarrierPointToPointRate';
  carrierPointToPointRateDateSpan?: Maybe<CarrierPointToPointRateDateSpan>;
  carrierPointToPointRateDateSpanKey: Scalars['Int'];
  carrierPointToPointRateKey: Scalars['Int'];
  destinationCity?: Maybe<City>;
  destinationCityKey: Scalars['Int'];
  miles: Scalars['Int'];
  originCity?: Maybe<City>;
  originCityKey: Scalars['Int'];
  rate: Scalars['Float'];
  tolls: Scalars['Float'];
};

export type CarrierPointToPointRateDateSpan = {
  __typename?: 'CarrierPointToPointRateDateSpan';
  carrier?: Maybe<Carrier>;
  carrierKey: Scalars['Int'];
  carrierPointToPointRateDateSpanKey: Scalars['Int'];
  carrierPointToPointRates?: Maybe<Array<Maybe<CarrierPointToPointRate>>>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type CarrierPointToPointRateDateSpanFilterInput = {
  and?: InputMaybe<Array<CarrierPointToPointRateDateSpanFilterInput>>;
  carrier?: InputMaybe<CarrierFilterInput>;
  carrierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  carrierPointToPointRateDateSpanKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  carrierPointToPointRates?: InputMaybe<ListFilterInputTypeOfCarrierPointToPointRateFilterInput>;
  endDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CarrierPointToPointRateDateSpanFilterInput>>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type CarrierPointToPointRateFilterInput = {
  and?: InputMaybe<Array<CarrierPointToPointRateFilterInput>>;
  carrierPointToPointRateDateSpan?: InputMaybe<CarrierPointToPointRateDateSpanFilterInput>;
  carrierPointToPointRateDateSpanKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  carrierPointToPointRateKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  destinationCity?: InputMaybe<CityFilterInput>;
  destinationCityKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  miles?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CarrierPointToPointRateFilterInput>>;
  originCity?: InputMaybe<CityFilterInput>;
  originCityKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  rate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  tolls?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type CarrierRate = {
  __typename?: 'CarrierRate';
  basePercent: Scalars['Float'];
  carrier?: Maybe<Carrier>;
  carrierKey: Scalars['Int'];
  carrierRateBands?: Maybe<Array<Maybe<CarrierRateBand>>>;
  carrierRateKey: Scalars['Int'];
  default: Scalars['Boolean'];
  endDate: Scalars['DateTime'];
  fuelPercent: Scalars['Float'];
  startDate: Scalars['DateTime'];
};

export type CarrierRateBand = {
  __typename?: 'CarrierRateBand';
  carrierRate?: Maybe<CarrierRate>;
  carrierRateBandKey: Scalars['Int'];
  carrierRateKey: Scalars['Int'];
  mileageFrom: Scalars['Int'];
  mileageTo: Scalars['Int'];
  ratePerGallon: Scalars['Float'];
};

export type CarrierRateBandFilterInput = {
  and?: InputMaybe<Array<CarrierRateBandFilterInput>>;
  carrierRate?: InputMaybe<CarrierRateFilterInput>;
  carrierRateBandKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  carrierRateKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  mileageFrom?: InputMaybe<ComparableInt32OperationFilterInput>;
  mileageTo?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CarrierRateBandFilterInput>>;
  ratePerGallon?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type CarrierRateFilterInput = {
  and?: InputMaybe<Array<CarrierRateFilterInput>>;
  basePercent?: InputMaybe<ComparableDoubleOperationFilterInput>;
  carrier?: InputMaybe<CarrierFilterInput>;
  carrierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  carrierRateBands?: InputMaybe<ListFilterInputTypeOfCarrierRateBandFilterInput>;
  carrierRateKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  fuelPercent?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<CarrierRateFilterInput>>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type CarrierSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  carrierKey?: InputMaybe<SortEnumType>;
  carrierType?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  isArchived?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  zipCode?: InputMaybe<SortEnumType>;
};

export enum CarrierType {
  Mileage = 'MILEAGE',
  PointToPoint = 'POINT_TO_POINT'
}

export type CarrierTypeOperationFilterInput = {
  eq?: InputMaybe<CarrierType>;
  in?: InputMaybe<Array<CarrierType>>;
  neq?: InputMaybe<CarrierType>;
  nin?: InputMaybe<Array<CarrierType>>;
};

export type City = {
  __typename?: 'City';
  cityKey: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  state?: Maybe<State>;
  stateKey: Scalars['Int'];
};

export type CityFilterInput = {
  and?: InputMaybe<Array<CityFilterInput>>;
  cityKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CityFilterInput>>;
  state?: InputMaybe<StateFilterInput>;
  stateKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOffsetOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type Contact = {
  __typename?: 'Contact';
  carrier?: Maybe<Carrier>;
  carrierKey?: Maybe<Scalars['Int']>;
  cellPhone?: Maybe<Scalars['String']>;
  contactKey: Scalars['Int'];
  default: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locationContacts?: Maybe<Array<Maybe<Location>>>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  supplierKey?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  terminal?: Maybe<Terminal>;
  terminalKey?: Maybe<Scalars['Int']>;
};

export type ContactFilterInput = {
  and?: InputMaybe<Array<ContactFilterInput>>;
  carrier?: InputMaybe<CarrierFilterInput>;
  carrierKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  cellPhone?: InputMaybe<StringOperationFilterInput>;
  contactKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  deleted?: InputMaybe<BooleanOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  homePhone?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  locationContacts?: InputMaybe<ListFilterInputTypeOfLocationContactFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  tasks?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export enum ContactMethod {
  Chat = 'CHAT',
  Email = 'EMAIL',
  Fax = 'FAX',
  Phone = 'PHONE'
}

export type ContactMethodOperationFilterInput = {
  eq?: InputMaybe<ContactMethod>;
  in?: InputMaybe<Array<ContactMethod>>;
  neq?: InputMaybe<ContactMethod>;
  nin?: InputMaybe<Array<ContactMethod>>;
};

export enum ContractPeriod {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type ContractPeriodOperationFilterInput = {
  eq?: InputMaybe<ContractPeriod>;
  in?: InputMaybe<Array<ContractPeriod>>;
  neq?: InputMaybe<ContractPeriod>;
  nin?: InputMaybe<Array<ContractPeriod>>;
};

export enum ContractType {
  DayOf = 'DAY_OF',
  DayPrior = 'DAY_PRIOR'
}

export type ContractTypeOperationFilterInput = {
  eq?: InputMaybe<ContractType>;
  in?: InputMaybe<Array<ContractType>>;
  neq?: InputMaybe<ContractType>;
  nin?: InputMaybe<Array<ContractType>>;
};

export type ContractVolume = {
  __typename?: 'ContractVolume';
  april: Scalars['Int'];
  august: Scalars['Int'];
  contractVolumeKey: Scalars['Int'];
  contractVolumeTerminals?: Maybe<Array<Maybe<ContractVolumeTerminal>>>;
  contractVolumeType: ContractVolumeType;
  december: Scalars['Int'];
  february: Scalars['Int'];
  january: Scalars['Int'];
  july: Scalars['Int'];
  june: Scalars['Int'];
  march: Scalars['Int'];
  may: Scalars['Int'];
  november: Scalars['Int'];
  october: Scalars['Int'];
  september: Scalars['Int'];
  startDate: Scalars['DateTime'];
  supplier?: Maybe<Supplier>;
  supplierKey: Scalars['Int'];
};

export type ContractVolumeFilterInput = {
  and?: InputMaybe<Array<ContractVolumeFilterInput>>;
  april?: InputMaybe<ComparableInt32OperationFilterInput>;
  august?: InputMaybe<ComparableInt32OperationFilterInput>;
  contractVolumeKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  contractVolumeTerminals?: InputMaybe<ListFilterInputTypeOfContractVolumeTerminalFilterInput>;
  contractVolumeType?: InputMaybe<ContractVolumeTypeOperationFilterInput>;
  december?: InputMaybe<ComparableInt32OperationFilterInput>;
  february?: InputMaybe<ComparableInt32OperationFilterInput>;
  january?: InputMaybe<ComparableInt32OperationFilterInput>;
  july?: InputMaybe<ComparableInt32OperationFilterInput>;
  june?: InputMaybe<ComparableInt32OperationFilterInput>;
  march?: InputMaybe<ComparableInt32OperationFilterInput>;
  may?: InputMaybe<ComparableInt32OperationFilterInput>;
  november?: InputMaybe<ComparableInt32OperationFilterInput>;
  october?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<ContractVolumeFilterInput>>;
  september?: InputMaybe<ComparableInt32OperationFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ContractVolumeTerminal = {
  __typename?: 'ContractVolumeTerminal';
  contractVolume?: Maybe<Supplier>;
  contractVolumeKey: Scalars['Int'];
  terminal?: Maybe<Terminal>;
  terminalKey: Scalars['Int'];
};

export type ContractVolumeTerminalFilterInput = {
  and?: InputMaybe<Array<ContractVolumeTerminalFilterInput>>;
  contractVolume?: InputMaybe<ContractVolumeFilterInput>;
  contractVolumeKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<ContractVolumeTerminalFilterInput>>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export enum ContractVolumeType {
  Combine = 'COMBINE',
  Index = 'INDEX',
  Posting = 'POSTING'
}

export type ContractVolumeTypeOperationFilterInput = {
  eq?: InputMaybe<ContractVolumeType>;
  in?: InputMaybe<Array<ContractVolumeType>>;
  neq?: InputMaybe<ContractVolumeType>;
  nin?: InputMaybe<Array<ContractVolumeType>>;
};

export type Customer = {
  __typename?: 'Customer';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  businessType: BusinessType;
  city?: Maybe<Scalars['String']>;
  creditLimit: Scalars['Float'];
  creditNotes?: Maybe<Scalars['String']>;
  customerKey: Scalars['Int'];
  customerTags?: Maybe<Array<CustomerTag>>;
  dbaName?: Maybe<Scalars['String']>;
  deliveryTime?: Maybe<DeliveryTime>;
  deliveryTimeKey?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  locations: Array<Location>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  quickBooksName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CustomerFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CustomerFilterInput>>;
  businessType?: InputMaybe<BusinessTypeOperationFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  creditLimit?: InputMaybe<ComparableDoubleOperationFilterInput>;
  creditNotes?: InputMaybe<StringOperationFilterInput>;
  customerKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  customerTags?: InputMaybe<ListFilterInputTypeOfCustomerTagFilterInput>;
  dbaName?: InputMaybe<StringOperationFilterInput>;
  deliveryTime?: InputMaybe<DeliveryTimeFilterInput>;
  deliveryTimeKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  directions?: InputMaybe<StringOperationFilterInput>;
  isArchived?: InputMaybe<BooleanOperationFilterInput>;
  locations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  quickBooksName?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  zipCode?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  businessType?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  creditLimit?: InputMaybe<SortEnumType>;
  creditNotes?: InputMaybe<SortEnumType>;
  customerKey?: InputMaybe<SortEnumType>;
  dbaName?: InputMaybe<SortEnumType>;
  deliveryTime?: InputMaybe<DeliveryTimeSortInput>;
  deliveryTimeKey?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  directions?: InputMaybe<SortEnumType>;
  isArchived?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  quickBooksName?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  zipCode?: InputMaybe<SortEnumType>;
};

export type CustomerTag = {
  __typename?: 'CustomerTag';
  customer?: Maybe<Customer>;
  customerKey: Scalars['Int'];
  tag?: Maybe<Tag>;
  tagKey: Scalars['Int'];
};

export type CustomerTagFilterInput = {
  and?: InputMaybe<Array<CustomerTagFilterInput>>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CustomerTagFilterInput>>;
  tag?: InputMaybe<TagFilterInput>;
  tagKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type DeliveryTime = {
  __typename?: 'DeliveryTime';
  code?: Maybe<Scalars['String']>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  deliveryTimeKey: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
};

export type DeliveryTimeFilterInput = {
  and?: InputMaybe<Array<DeliveryTimeFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCustomerFilterInput>;
  deliveryTimeKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeliveryTimeFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
};

export type DeliveryTimeSortInput = {
  code?: InputMaybe<SortEnumType>;
  deliveryTimeKey?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
};

export type DirectMarkup = {
  __typename?: 'DirectMarkup';
  directMarkupKey: Scalars['Int'];
  directMarkupType: DirectMarkupType;
  isArchived: Scalars['Boolean'];
  location?: Maybe<Location>;
  locationKey: Scalars['Int'];
  markup: Scalars['Float'];
  supplier?: Maybe<Supplier>;
  supplierKey: Scalars['Int'];
  terminal?: Maybe<Terminal>;
  terminalKey: Scalars['Int'];
};

export type DirectMarkupFilterInput = {
  and?: InputMaybe<Array<DirectMarkupFilterInput>>;
  directMarkupKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  directMarkupType?: InputMaybe<DirectMarkupTypeOperationFilterInput>;
  isArchived?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  markup?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<DirectMarkupFilterInput>>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export enum DirectMarkupType {
  Both = 'BOTH',
  Index = 'INDEX',
  Posting = 'POSTING'
}

export type DirectMarkupTypeOperationFilterInput = {
  eq?: InputMaybe<DirectMarkupType>;
  in?: InputMaybe<Array<DirectMarkupType>>;
  neq?: InputMaybe<DirectMarkupType>;
  nin?: InputMaybe<Array<DirectMarkupType>>;
};

export type FileNumber = {
  __typename?: 'FileNumber';
  fileNumberKey: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  supplier?: Maybe<Supplier>;
  supplierKey: Scalars['Int'];
  terminal?: Maybe<Terminal>;
  terminalKey: Scalars['Int'];
};

export type FileNumberFilterInput = {
  and?: InputMaybe<Array<FileNumberFilterInput>>;
  fileNumberKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  isArchived?: InputMaybe<BooleanOperationFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FileNumberFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type FileNumberSortInput = {
  fileNumberKey?: InputMaybe<SortEnumType>;
  isArchived?: InputMaybe<SortEnumType>;
  isDefault?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SupplierSortInput>;
  supplierKey?: InputMaybe<SortEnumType>;
  terminal?: InputMaybe<TerminalSortInput>;
  terminalKey?: InputMaybe<SortEnumType>;
};

export type FuelSurcharge = {
  __typename?: 'FuelSurcharge';
  carrier?: Maybe<Carrier>;
  carrierKey: Scalars['Int'];
  endDate: Scalars['DateTime'];
  fuelSurchargeKey: Scalars['Int'];
  rate: Scalars['Float'];
  startDate: Scalars['DateTime'];
};

export type FuelSurchargeFilterInput = {
  and?: InputMaybe<Array<FuelSurchargeFilterInput>>;
  carrier?: InputMaybe<CarrierFilterInput>;
  carrierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  fuelSurchargeKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<FuelSurchargeFilterInput>>;
  rate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type IndexContract = {
  __typename?: 'IndexContract';
  accountInfo?: Maybe<Scalars['String']>;
  aprilIndex: Scalars['Float'];
  augustIndex: Scalars['Float'];
  basisType: BasisType;
  basisTypeKey: Scalars['Int'];
  contractPeriod: ContractPeriod;
  contractType: ContractType;
  dayPrior: Scalars['Boolean'];
  decemberIndex: Scalars['Float'];
  effectiveDate: Scalars['DateTime'];
  effectiveEndDate: Scalars['DateTime'];
  februaryIndex: Scalars['Float'];
  fileNumber?: Maybe<Scalars['String']>;
  indexContractKey: Scalars['Int'];
  isIndication: Scalars['Boolean'];
  januaryIndex: Scalars['Float'];
  julyIndex: Scalars['Float'];
  juneIndex: Scalars['Float'];
  marchIndex: Scalars['Float'];
  maximumGallons?: Maybe<Scalars['Float']>;
  maximumLoads?: Maybe<Scalars['Int']>;
  mayIndex: Scalars['Float'];
  minimumGallons?: Maybe<Scalars['Float']>;
  minimumLoads?: Maybe<Scalars['Int']>;
  novemberIndex: Scalars['Float'];
  octoberIndex: Scalars['Float'];
  orders?: Maybe<Array<Maybe<Order>>>;
  septemberIndex: Scalars['Float'];
  supplier: Supplier;
  supplierKey: Scalars['Int'];
  supplierType: SupplierType;
  terminal: Terminal;
  terminalKey: Scalars['Int'];
};

export type IndexContractFilterInput = {
  accountInfo?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<IndexContractFilterInput>>;
  aprilIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  augustIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  basisType?: InputMaybe<BasisTypeFilterInput>;
  basisTypeKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  contractPeriod?: InputMaybe<ContractPeriodOperationFilterInput>;
  contractType?: InputMaybe<ContractTypeOperationFilterInput>;
  dayPrior?: InputMaybe<BooleanOperationFilterInput>;
  decemberIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  effectiveDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  effectiveEndDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  februaryIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  fileNumber?: InputMaybe<StringOperationFilterInput>;
  indexContractKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  isIndication?: InputMaybe<BooleanOperationFilterInput>;
  januaryIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  julyIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  juneIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  marchIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  maximumGallons?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  maximumLoads?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  mayIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  minimumGallons?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  minimumLoads?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  novemberIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  octoberIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<IndexContractFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  septemberIndex?: InputMaybe<ComparableDoubleOperationFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  supplierType?: InputMaybe<SupplierTypeOperationFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type IndexContractSortInput = {
  accountInfo?: InputMaybe<SortEnumType>;
  aprilIndex?: InputMaybe<SortEnumType>;
  augustIndex?: InputMaybe<SortEnumType>;
  basisType?: InputMaybe<BasisTypeSortInput>;
  basisTypeKey?: InputMaybe<SortEnumType>;
  contractPeriod?: InputMaybe<SortEnumType>;
  contractType?: InputMaybe<SortEnumType>;
  dayPrior?: InputMaybe<SortEnumType>;
  decemberIndex?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  effectiveEndDate?: InputMaybe<SortEnumType>;
  februaryIndex?: InputMaybe<SortEnumType>;
  fileNumber?: InputMaybe<SortEnumType>;
  indexContractKey?: InputMaybe<SortEnumType>;
  isIndication?: InputMaybe<SortEnumType>;
  januaryIndex?: InputMaybe<SortEnumType>;
  julyIndex?: InputMaybe<SortEnumType>;
  juneIndex?: InputMaybe<SortEnumType>;
  marchIndex?: InputMaybe<SortEnumType>;
  maximumGallons?: InputMaybe<SortEnumType>;
  maximumLoads?: InputMaybe<SortEnumType>;
  mayIndex?: InputMaybe<SortEnumType>;
  minimumGallons?: InputMaybe<SortEnumType>;
  minimumLoads?: InputMaybe<SortEnumType>;
  novemberIndex?: InputMaybe<SortEnumType>;
  octoberIndex?: InputMaybe<SortEnumType>;
  septemberIndex?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SupplierSortInput>;
  supplierKey?: InputMaybe<SortEnumType>;
  supplierType?: InputMaybe<SortEnumType>;
  terminal?: InputMaybe<TerminalSortInput>;
  terminalKey?: InputMaybe<SortEnumType>;
};

export type LinkedSalesAgreement = {
  __typename?: 'LinkedSalesAgreement';
  relatedFromSalesAgreement?: Maybe<SalesAgreement>;
  relatedFromSalesAgreementKey: Scalars['Int'];
  relatedFromSalesAgreements?: Maybe<Array<SalesAgreement>>;
  relatedToSalesAgreement?: Maybe<SalesAgreement>;
  relatedToSalesAgreementKey: Scalars['Int'];
};

export type LinkedSalesAgreementFilterInput = {
  and?: InputMaybe<Array<LinkedSalesAgreementFilterInput>>;
  or?: InputMaybe<Array<LinkedSalesAgreementFilterInput>>;
  relatedFromSalesAgreement?: InputMaybe<SalesAgreementFilterInput>;
  relatedFromSalesAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  relatedToSalesAgreement?: InputMaybe<SalesAgreementFilterInput>;
  relatedToSalesAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ListFilterInputTypeOfBasisTypePriceFilterInput = {
  all?: InputMaybe<BasisTypePriceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BasisTypePriceFilterInput>;
  some?: InputMaybe<BasisTypePriceFilterInput>;
};

export type ListFilterInputTypeOfCarrierPointToPointRateDateSpanFilterInput = {
  all?: InputMaybe<CarrierPointToPointRateDateSpanFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CarrierPointToPointRateDateSpanFilterInput>;
  some?: InputMaybe<CarrierPointToPointRateDateSpanFilterInput>;
};

export type ListFilterInputTypeOfCarrierPointToPointRateFilterInput = {
  all?: InputMaybe<CarrierPointToPointRateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CarrierPointToPointRateFilterInput>;
  some?: InputMaybe<CarrierPointToPointRateFilterInput>;
};

export type ListFilterInputTypeOfCarrierRateBandFilterInput = {
  all?: InputMaybe<CarrierRateBandFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CarrierRateBandFilterInput>;
  some?: InputMaybe<CarrierRateBandFilterInput>;
};

export type ListFilterInputTypeOfCarrierRateFilterInput = {
  all?: InputMaybe<CarrierRateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CarrierRateFilterInput>;
  some?: InputMaybe<CarrierRateFilterInput>;
};

export type ListFilterInputTypeOfCityFilterInput = {
  all?: InputMaybe<CityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CityFilterInput>;
  some?: InputMaybe<CityFilterInput>;
};

export type ListFilterInputTypeOfContactFilterInput = {
  all?: InputMaybe<ContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ContactFilterInput>;
  some?: InputMaybe<ContactFilterInput>;
};

export type ListFilterInputTypeOfContractVolumeFilterInput = {
  all?: InputMaybe<ContractVolumeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ContractVolumeFilterInput>;
  some?: InputMaybe<ContractVolumeFilterInput>;
};

export type ListFilterInputTypeOfContractVolumeTerminalFilterInput = {
  all?: InputMaybe<ContractVolumeTerminalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ContractVolumeTerminalFilterInput>;
  some?: InputMaybe<ContractVolumeTerminalFilterInput>;
};

export type ListFilterInputTypeOfCustomerFilterInput = {
  all?: InputMaybe<CustomerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerFilterInput>;
  some?: InputMaybe<CustomerFilterInput>;
};

export type ListFilterInputTypeOfCustomerTagFilterInput = {
  all?: InputMaybe<CustomerTagFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerTagFilterInput>;
  some?: InputMaybe<CustomerTagFilterInput>;
};

export type ListFilterInputTypeOfDirectMarkupFilterInput = {
  all?: InputMaybe<DirectMarkupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DirectMarkupFilterInput>;
  some?: InputMaybe<DirectMarkupFilterInput>;
};

export type ListFilterInputTypeOfFileNumberFilterInput = {
  all?: InputMaybe<FileNumberFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FileNumberFilterInput>;
  some?: InputMaybe<FileNumberFilterInput>;
};

export type ListFilterInputTypeOfFuelSurchargeFilterInput = {
  all?: InputMaybe<FuelSurchargeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FuelSurchargeFilterInput>;
  some?: InputMaybe<FuelSurchargeFilterInput>;
};

export type ListFilterInputTypeOfIndexContractFilterInput = {
  all?: InputMaybe<IndexContractFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<IndexContractFilterInput>;
  some?: InputMaybe<IndexContractFilterInput>;
};

export type ListFilterInputTypeOfLinkedSalesAgreementFilterInput = {
  all?: InputMaybe<LinkedSalesAgreementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LinkedSalesAgreementFilterInput>;
  some?: InputMaybe<LinkedSalesAgreementFilterInput>;
};

export type ListFilterInputTypeOfLocationContactFilterInput = {
  all?: InputMaybe<LocationContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocationContactFilterInput>;
  some?: InputMaybe<LocationContactFilterInput>;
};

export type ListFilterInputTypeOfLocationFilterInput = {
  all?: InputMaybe<LocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocationFilterInput>;
  some?: InputMaybe<LocationFilterInput>;
};

export type ListFilterInputTypeOfLocationTagFilterInput = {
  all?: InputMaybe<LocationTagFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocationTagFilterInput>;
  some?: InputMaybe<LocationTagFilterInput>;
};

export type ListFilterInputTypeOfLocationTerminalFilterInput = {
  all?: InputMaybe<LocationTerminalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocationTerminalFilterInput>;
  some?: InputMaybe<LocationTerminalFilterInput>;
};

export type ListFilterInputTypeOfOrderFilterInput = {
  all?: InputMaybe<OrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OrderFilterInput>;
  some?: InputMaybe<OrderFilterInput>;
};

export type ListFilterInputTypeOfOrderTripNumberFillerFilterInput = {
  all?: InputMaybe<OrderTripNumberFillerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OrderTripNumberFillerFilterInput>;
  some?: InputMaybe<OrderTripNumberFillerFilterInput>;
};

export type ListFilterInputTypeOfPostingFilterInput = {
  all?: InputMaybe<PostingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PostingFilterInput>;
  some?: InputMaybe<PostingFilterInput>;
};

export type ListFilterInputTypeOfPrePurchaseAgreementFilterInput = {
  all?: InputMaybe<PrePurchaseAgreementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PrePurchaseAgreementFilterInput>;
  some?: InputMaybe<PrePurchaseAgreementFilterInput>;
};

export type ListFilterInputTypeOfPrePurchaseAgreementSalesAgreementFilterInput = {
  all?: InputMaybe<PrePurchaseAgreementSalesAgreementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PrePurchaseAgreementSalesAgreementFilterInput>;
  some?: InputMaybe<PrePurchaseAgreementSalesAgreementFilterInput>;
};

export type ListFilterInputTypeOfRefreshTokenFilterInput = {
  all?: InputMaybe<RefreshTokenFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RefreshTokenFilterInput>;
  some?: InputMaybe<RefreshTokenFilterInput>;
};

export type ListFilterInputTypeOfSalesAgreementFilterInput = {
  all?: InputMaybe<SalesAgreementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SalesAgreementFilterInput>;
  some?: InputMaybe<SalesAgreementFilterInput>;
};

export type ListFilterInputTypeOfSalesAgreementLocationFilterInput = {
  all?: InputMaybe<SalesAgreementLocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SalesAgreementLocationFilterInput>;
  some?: InputMaybe<SalesAgreementLocationFilterInput>;
};

export type ListFilterInputTypeOfStateTaxFilterInput = {
  all?: InputMaybe<StateTaxFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StateTaxFilterInput>;
  some?: InputMaybe<StateTaxFilterInput>;
};

export type ListFilterInputTypeOfSwapDealFilterInput = {
  all?: InputMaybe<SwapDealFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SwapDealFilterInput>;
  some?: InputMaybe<SwapDealFilterInput>;
};

export type ListFilterInputTypeOfSwapDealSalesAgreementFilterInput = {
  all?: InputMaybe<SwapDealSalesAgreementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SwapDealSalesAgreementFilterInput>;
  some?: InputMaybe<SwapDealSalesAgreementFilterInput>;
};

export type ListFilterInputTypeOfTankFilterInput = {
  all?: InputMaybe<TankFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TankFilterInput>;
  some?: InputMaybe<TankFilterInput>;
};

export type ListFilterInputTypeOfTaskFilterInput = {
  all?: InputMaybe<TaskFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TaskFilterInput>;
  some?: InputMaybe<TaskFilterInput>;
};

export type ListFilterInputTypeOfTermContractFilterInput = {
  all?: InputMaybe<TermContractFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TermContractFilterInput>;
  some?: InputMaybe<TermContractFilterInput>;
};

export type ListFilterInputTypeOfTermContractLocationFilterInput = {
  all?: InputMaybe<TermContractLocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TermContractLocationFilterInput>;
  some?: InputMaybe<TermContractLocationFilterInput>;
};

export type ListFilterInputTypeOfTermContractPrePurchaseAgreementFilterInput = {
  all?: InputMaybe<TermContractPrePurchaseAgreementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TermContractPrePurchaseAgreementFilterInput>;
  some?: InputMaybe<TermContractPrePurchaseAgreementFilterInput>;
};

export type ListFilterInputTypeOfTermContractSalesAgreementFilterInput = {
  all?: InputMaybe<TermContractSalesAgreementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TermContractSalesAgreementFilterInput>;
  some?: InputMaybe<TermContractSalesAgreementFilterInput>;
};

export type ListFilterInputTypeOfTermContractSupplierFilterInput = {
  all?: InputMaybe<TermContractSupplierFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TermContractSupplierFilterInput>;
  some?: InputMaybe<TermContractSupplierFilterInput>;
};

export type ListFilterInputTypeOfTermContractTerminalFilterInput = {
  all?: InputMaybe<TermContractTerminalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TermContractTerminalFilterInput>;
  some?: InputMaybe<TermContractTerminalFilterInput>;
};

export type ListFilterInputTypeOfUserCarrierFilterInput = {
  all?: InputMaybe<UserCarrierFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserCarrierFilterInput>;
  some?: InputMaybe<UserCarrierFilterInput>;
};

export type Location = {
  __typename?: 'Location';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  agId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  customer: Customer;
  customerKey: Scalars['Int'];
  directMarkups?: Maybe<Array<Maybe<DirectMarkup>>>;
  directions?: Maybe<Scalars['String']>;
  federalId?: Maybe<Scalars['String']>;
  hasPercCharge: Scalars['Boolean'];
  isAgExempt: Scalars['Boolean'];
  isFobCustomer: Scalars['Boolean'];
  isInternetCustomer: Scalars['Boolean'];
  isTaxExempt: Scalars['Boolean'];
  locationContacts?: Maybe<Array<Maybe<LocationContact>>>;
  locationKey: Scalars['Int'];
  locationNotes?: Maybe<Scalars['String']>;
  locationStatus: LocationStatus;
  locationTags?: Maybe<Array<LocationTag>>;
  locationTerminals?: Maybe<Array<Maybe<LocationTerminal>>>;
  lockMarkup: Scalars['Boolean'];
  mailToAddress1?: Maybe<Scalars['String']>;
  mailToAddress2?: Maybe<Scalars['String']>;
  mailToCity?: Maybe<Scalars['String']>;
  mailToDirections?: Maybe<Scalars['String']>;
  mailToSameAsCustomer: Scalars['Boolean'];
  mailToState?: Maybe<Scalars['String']>;
  mailToZipCode?: Maybe<Scalars['String']>;
  markupCost: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  percentOfSales?: Maybe<Scalars['Int']>;
  primaryCarrier?: Maybe<Carrier>;
  primaryCarrierKey?: Maybe<Scalars['Int']>;
  quickBooksName?: Maybe<Scalars['String']>;
  salesAgreementLocations?: Maybe<Array<Maybe<SalesAgreementLocation>>>;
  secondaryCarrier?: Maybe<Carrier>;
  secondaryCarrierKey?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  tanks?: Maybe<Array<Maybe<Tank>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  termContractLocations?: Maybe<Array<Maybe<TermContractLocation>>>;
  zipCode?: Maybe<Scalars['String']>;
};

export type LocationContact = {
  __typename?: 'LocationContact';
  contact?: Maybe<Contact>;
  contactKey: Scalars['Int'];
  default: Scalars['Boolean'];
  location?: Maybe<Array<Maybe<Location>>>;
  locationKey: Scalars['Int'];
};

export type LocationContactFilterInput = {
  and?: InputMaybe<Array<LocationContactFilterInput>>;
  contact?: InputMaybe<ContactFilterInput>;
  contactKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<LocationContactFilterInput>>;
};

export type LocationFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  agId?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<LocationFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  directMarkups?: InputMaybe<ListFilterInputTypeOfDirectMarkupFilterInput>;
  directions?: InputMaybe<StringOperationFilterInput>;
  federalId?: InputMaybe<StringOperationFilterInput>;
  hasPercCharge?: InputMaybe<BooleanOperationFilterInput>;
  isAgExempt?: InputMaybe<BooleanOperationFilterInput>;
  isFobCustomer?: InputMaybe<BooleanOperationFilterInput>;
  isInternetCustomer?: InputMaybe<BooleanOperationFilterInput>;
  isTaxExempt?: InputMaybe<BooleanOperationFilterInput>;
  locationContacts?: InputMaybe<ListFilterInputTypeOfLocationContactFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  locationNotes?: InputMaybe<StringOperationFilterInput>;
  locationStatus?: InputMaybe<LocationStatusOperationFilterInput>;
  locationTags?: InputMaybe<ListFilterInputTypeOfLocationTagFilterInput>;
  locationTerminals?: InputMaybe<ListFilterInputTypeOfLocationTerminalFilterInput>;
  lockMarkup?: InputMaybe<BooleanOperationFilterInput>;
  mailToAddress1?: InputMaybe<StringOperationFilterInput>;
  mailToAddress2?: InputMaybe<StringOperationFilterInput>;
  mailToCity?: InputMaybe<StringOperationFilterInput>;
  mailToDirections?: InputMaybe<StringOperationFilterInput>;
  mailToSameAsCustomer?: InputMaybe<BooleanOperationFilterInput>;
  mailToState?: InputMaybe<StringOperationFilterInput>;
  mailToZipCode?: InputMaybe<StringOperationFilterInput>;
  markupCost?: InputMaybe<ComparableDoubleOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  percentOfSales?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  primaryCarrier?: InputMaybe<CarrierFilterInput>;
  primaryCarrierKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  quickBooksName?: InputMaybe<StringOperationFilterInput>;
  salesAgreementLocations?: InputMaybe<ListFilterInputTypeOfSalesAgreementLocationFilterInput>;
  secondaryCarrier?: InputMaybe<CarrierFilterInput>;
  secondaryCarrierKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  stateId?: InputMaybe<StringOperationFilterInput>;
  tanks?: InputMaybe<ListFilterInputTypeOfTankFilterInput>;
  tasks?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  termContractLocations?: InputMaybe<ListFilterInputTypeOfTermContractLocationFilterInput>;
  zipCode?: InputMaybe<StringOperationFilterInput>;
};

export type LocationSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  agId?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  customerKey?: InputMaybe<SortEnumType>;
  directions?: InputMaybe<SortEnumType>;
  federalId?: InputMaybe<SortEnumType>;
  hasPercCharge?: InputMaybe<SortEnumType>;
  isAgExempt?: InputMaybe<SortEnumType>;
  isFobCustomer?: InputMaybe<SortEnumType>;
  isInternetCustomer?: InputMaybe<SortEnumType>;
  isTaxExempt?: InputMaybe<SortEnumType>;
  locationKey?: InputMaybe<SortEnumType>;
  locationNotes?: InputMaybe<SortEnumType>;
  locationStatus?: InputMaybe<SortEnumType>;
  lockMarkup?: InputMaybe<SortEnumType>;
  mailToAddress1?: InputMaybe<SortEnumType>;
  mailToAddress2?: InputMaybe<SortEnumType>;
  mailToCity?: InputMaybe<SortEnumType>;
  mailToDirections?: InputMaybe<SortEnumType>;
  mailToSameAsCustomer?: InputMaybe<SortEnumType>;
  mailToState?: InputMaybe<SortEnumType>;
  mailToZipCode?: InputMaybe<SortEnumType>;
  markupCost?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  percentOfSales?: InputMaybe<SortEnumType>;
  primaryCarrier?: InputMaybe<CarrierSortInput>;
  primaryCarrierKey?: InputMaybe<SortEnumType>;
  quickBooksName?: InputMaybe<SortEnumType>;
  secondaryCarrier?: InputMaybe<CarrierSortInput>;
  secondaryCarrierKey?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  stateId?: InputMaybe<SortEnumType>;
  zipCode?: InputMaybe<SortEnumType>;
};

export enum LocationStatus {
  Active = 'ACTIVE',
  ApprovedCredit = 'APPROVED_CREDIT',
  CreditRequired = 'CREDIT_REQUIRED',
  Inactive = 'INACTIVE',
  NoSale = 'NO_SALE'
}

export type LocationStatusOperationFilterInput = {
  eq?: InputMaybe<LocationStatus>;
  in?: InputMaybe<Array<LocationStatus>>;
  neq?: InputMaybe<LocationStatus>;
  nin?: InputMaybe<Array<LocationStatus>>;
};

export type LocationTag = {
  __typename?: 'LocationTag';
  location?: Maybe<Location>;
  locationKey: Scalars['Int'];
  tag?: Maybe<Tag>;
  tagKey: Scalars['Int'];
};

export type LocationTagFilterInput = {
  and?: InputMaybe<Array<LocationTagFilterInput>>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<LocationTagFilterInput>>;
  tag?: InputMaybe<TagFilterInput>;
  tagKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type LocationTerminal = {
  __typename?: 'LocationTerminal';
  billingFreight?: Maybe<Scalars['Int']>;
  freight?: Maybe<Scalars['Int']>;
  location: Location;
  locationKey: Scalars['Int'];
  mileage: Scalars['Int'];
  terminal: Terminal;
  terminalKey: Scalars['Int'];
};

export type LocationTerminalFilterInput = {
  and?: InputMaybe<Array<LocationTerminalFilterInput>>;
  billingFreight?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  freight?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  mileage?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<LocationTerminalFilterInput>>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  orderTripsUpdate: Scalars['Boolean'];
};


export type MutationOrderTripsUpdateArgs = {
  actualGallons: Scalars['Int'];
  manifest?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  orderKey: Scalars['Int'];
};

export type Order = {
  __typename?: 'Order';
  actualGallons?: Maybe<Scalars['Int']>;
  assessment: Scalars['Float'];
  carrier?: Maybe<Carrier>;
  carrierKey: Scalars['Int'];
  carrierPaid?: Maybe<Scalars['DateTime']>;
  costDescription?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Customer>;
  customerKey: Scalars['Int'];
  delivered: Scalars['Float'];
  deliveryDate: Scalars['DateTime'];
  deliveryTime?: Maybe<DeliveryTime>;
  deliveryTimeKey?: Maybe<Scalars['Int']>;
  fOB: Scalars['Float'];
  fileNumber?: Maybe<FileNumber>;
  fileNumberKey?: Maybe<Scalars['Int']>;
  freight: Scalars['Float'];
  gallons: Scalars['Int'];
  indexContract?: Maybe<IndexContract>;
  indexContractKey?: Maybe<Scalars['Int']>;
  legacyCostType?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  locationKey: Scalars['Int'];
  manifest?: Maybe<Scalars['String']>;
  markup: Scalars['Float'];
  miles: Scalars['Int'];
  misc?: Maybe<Scalars['Float']>;
  modifiedByUser?: Maybe<User>;
  modifiedByUserId?: Maybe<Scalars['Int']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  orderKey: Scalars['Int'];
  orderType: OrderType;
  otherCharge?: Maybe<Scalars['Float']>;
  otherCost?: Maybe<Scalars['Float']>;
  patCost?: Maybe<Scalars['Float']>;
  patCostTotal?: Maybe<Scalars['Float']>;
  perc?: Maybe<Scalars['Float']>;
  poNumber?: Maybe<Scalars['String']>;
  posting?: Maybe<Posting>;
  postingKey?: Maybe<Scalars['Int']>;
  prePurchaseAgreement?: Maybe<PrePurchaseAgreement>;
  prePurchaseAgreementKey?: Maybe<Scalars['Int']>;
  product: Scalars['Float'];
  quotedPrice: Scalars['Boolean'];
  readyForInvoicing: Scalars['Boolean'];
  salesAgreement?: Maybe<SalesAgreement>;
  salesAgreementKey?: Maybe<Scalars['Int']>;
  sglInvoiceNumber?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  spotContract: Scalars['Boolean'];
  stateAssessment?: Maybe<Scalars['Float']>;
  stateTax?: Maybe<Scalars['Float']>;
  status: OrderStatus;
  supplier?: Maybe<Supplier>;
  supplierKey: Scalars['Int'];
  supplierPaid?: Maybe<Scalars['DateTime']>;
  termContract?: Maybe<TermContract>;
  termContractKey?: Maybe<Scalars['Int']>;
  terminal?: Maybe<Terminal>;
  terminalKey: Scalars['Int'];
  tolls: Scalars['Float'];
  tripManifest?: Maybe<Scalars['String']>;
  tripNotes?: Maybe<Scalars['String']>;
  tripNumber?: Maybe<Scalars['String']>;
  tripsActualGallons?: Maybe<Scalars['Int']>;
};

export type OrderFilterInput = {
  actualGallons?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  and?: InputMaybe<Array<OrderFilterInput>>;
  assessment?: InputMaybe<ComparableDoubleOperationFilterInput>;
  carrier?: InputMaybe<CarrierFilterInput>;
  carrierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  carrierPaid?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  costDescription?: InputMaybe<StringOperationFilterInput>;
  createdByUser?: InputMaybe<UserFilterInput>;
  createdByUserId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  createdDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  delivered?: InputMaybe<ComparableDoubleOperationFilterInput>;
  deliveryDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  deliveryTime?: InputMaybe<DeliveryTimeFilterInput>;
  deliveryTimeKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  fOB?: InputMaybe<ComparableDoubleOperationFilterInput>;
  fileNumber?: InputMaybe<FileNumberFilterInput>;
  fileNumberKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  freight?: InputMaybe<ComparableDoubleOperationFilterInput>;
  gallons?: InputMaybe<ComparableInt32OperationFilterInput>;
  indexContract?: InputMaybe<IndexContractFilterInput>;
  indexContractKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  legacyCostType?: InputMaybe<StringOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  manifest?: InputMaybe<StringOperationFilterInput>;
  markup?: InputMaybe<ComparableDoubleOperationFilterInput>;
  miles?: InputMaybe<ComparableInt32OperationFilterInput>;
  misc?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  modifiedByUser?: InputMaybe<UserFilterInput>;
  modifiedByUserId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  modifiedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<OrderFilterInput>>;
  orderKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  orderType?: InputMaybe<OrderTypeOperationFilterInput>;
  otherCharge?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  otherCost?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  patCost?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  patCostTotal?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  perc?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  poNumber?: InputMaybe<StringOperationFilterInput>;
  posting?: InputMaybe<PostingFilterInput>;
  postingKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  prePurchaseAgreement?: InputMaybe<PrePurchaseAgreementFilterInput>;
  prePurchaseAgreementKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  product?: InputMaybe<ComparableDoubleOperationFilterInput>;
  quotedPrice?: InputMaybe<BooleanOperationFilterInput>;
  readyForInvoicing?: InputMaybe<BooleanOperationFilterInput>;
  salesAgreement?: InputMaybe<SalesAgreementFilterInput>;
  salesAgreementKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  sglInvoiceNumber?: InputMaybe<StringOperationFilterInput>;
  specialInstructions?: InputMaybe<StringOperationFilterInput>;
  spotContract?: InputMaybe<BooleanOperationFilterInput>;
  stateAssessment?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  stateTax?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  status?: InputMaybe<OrderStatusOperationFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  supplierPaid?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  termContract?: InputMaybe<TermContractFilterInput>;
  termContractKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  tolls?: InputMaybe<ComparableDoubleOperationFilterInput>;
  tripManifest?: InputMaybe<StringOperationFilterInput>;
  tripNotes?: InputMaybe<StringOperationFilterInput>;
  tripNumber?: InputMaybe<StringOperationFilterInput>;
  tripsActualGallons?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

/** A connection to a list of items. */
export type OrderGetAllConnection = {
  __typename?: 'OrderGetAllConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderGetAllEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrderGetAllEdge = {
  __typename?: 'OrderGetAllEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

/** A connection to a list of items. */
export type OrderGetForTripsDashboardConnection = {
  __typename?: 'OrderGetForTripsDashboardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrderGetForTripsDashboardEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrderGetForTripsDashboardEdge = {
  __typename?: 'OrderGetForTripsDashboardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export type OrderSortInput = {
  actualGallons?: InputMaybe<SortEnumType>;
  assessment?: InputMaybe<SortEnumType>;
  carrier?: InputMaybe<CarrierSortInput>;
  carrierKey?: InputMaybe<SortEnumType>;
  carrierPaid?: InputMaybe<SortEnumType>;
  costDescription?: InputMaybe<SortEnumType>;
  createdByUser?: InputMaybe<UserSortInput>;
  createdByUserId?: InputMaybe<SortEnumType>;
  createdDate?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  customerKey?: InputMaybe<SortEnumType>;
  delivered?: InputMaybe<SortEnumType>;
  deliveryDate?: InputMaybe<SortEnumType>;
  deliveryTime?: InputMaybe<DeliveryTimeSortInput>;
  deliveryTimeKey?: InputMaybe<SortEnumType>;
  fOB?: InputMaybe<SortEnumType>;
  fileNumber?: InputMaybe<FileNumberSortInput>;
  fileNumberKey?: InputMaybe<SortEnumType>;
  freight?: InputMaybe<SortEnumType>;
  gallons?: InputMaybe<SortEnumType>;
  indexContract?: InputMaybe<IndexContractSortInput>;
  indexContractKey?: InputMaybe<SortEnumType>;
  legacyCostType?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationKey?: InputMaybe<SortEnumType>;
  manifest?: InputMaybe<SortEnumType>;
  markup?: InputMaybe<SortEnumType>;
  miles?: InputMaybe<SortEnumType>;
  misc?: InputMaybe<SortEnumType>;
  modifiedByUser?: InputMaybe<UserSortInput>;
  modifiedByUserId?: InputMaybe<SortEnumType>;
  modifiedDate?: InputMaybe<SortEnumType>;
  orderKey?: InputMaybe<SortEnumType>;
  orderType?: InputMaybe<SortEnumType>;
  otherCharge?: InputMaybe<SortEnumType>;
  otherCost?: InputMaybe<SortEnumType>;
  patCost?: InputMaybe<SortEnumType>;
  patCostTotal?: InputMaybe<SortEnumType>;
  perc?: InputMaybe<SortEnumType>;
  poNumber?: InputMaybe<SortEnumType>;
  posting?: InputMaybe<PostingSortInput>;
  postingKey?: InputMaybe<SortEnumType>;
  prePurchaseAgreement?: InputMaybe<PrePurchaseAgreementSortInput>;
  prePurchaseAgreementKey?: InputMaybe<SortEnumType>;
  product?: InputMaybe<SortEnumType>;
  quotedPrice?: InputMaybe<SortEnumType>;
  readyForInvoicing?: InputMaybe<SortEnumType>;
  salesAgreement?: InputMaybe<SalesAgreementSortInput>;
  salesAgreementKey?: InputMaybe<SortEnumType>;
  sglInvoiceNumber?: InputMaybe<SortEnumType>;
  specialInstructions?: InputMaybe<SortEnumType>;
  spotContract?: InputMaybe<SortEnumType>;
  stateAssessment?: InputMaybe<SortEnumType>;
  stateTax?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SupplierSortInput>;
  supplierKey?: InputMaybe<SortEnumType>;
  supplierPaid?: InputMaybe<SortEnumType>;
  termContract?: InputMaybe<TermContractSortInput>;
  termContractKey?: InputMaybe<SortEnumType>;
  terminal?: InputMaybe<TerminalSortInput>;
  terminalKey?: InputMaybe<SortEnumType>;
  tolls?: InputMaybe<SortEnumType>;
  tripManifest?: InputMaybe<SortEnumType>;
  tripNotes?: InputMaybe<SortEnumType>;
  tripNumber?: InputMaybe<SortEnumType>;
  tripsActualGallons?: InputMaybe<SortEnumType>;
};

export enum OrderStatus {
  All = 'ALL',
  Closed = 'CLOSED',
  Dispatched = 'DISPATCHED',
  Open = 'OPEN'
}

export type OrderStatusOperationFilterInput = {
  eq?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  neq?: InputMaybe<OrderStatus>;
  nin?: InputMaybe<Array<OrderStatus>>;
};

export type OrderTripNumberFiller = {
  __typename?: 'OrderTripNumberFiller';
  carrier?: Maybe<Carrier>;
  carrierKey: Scalars['Int'];
  orderTripNumberFillerKey: Scalars['Int'];
  tripNumber?: Maybe<Scalars['String']>;
};

export type OrderTripNumberFillerFilterInput = {
  and?: InputMaybe<Array<OrderTripNumberFillerFilterInput>>;
  carrier?: InputMaybe<CarrierFilterInput>;
  carrierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<OrderTripNumberFillerFilterInput>>;
  orderTripNumberFillerKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  tripNumber?: InputMaybe<StringOperationFilterInput>;
};

export enum OrderType {
  IndexContract = 'INDEX_CONTRACT',
  Posting = 'POSTING',
  PrePurchaseAgreement = 'PRE_PURCHASE_AGREEMENT',
  SalesAgreement = 'SALES_AGREEMENT'
}

export type OrderTypeOperationFilterInput = {
  eq?: InputMaybe<OrderType>;
  in?: InputMaybe<Array<OrderType>>;
  neq?: InputMaybe<OrderType>;
  nin?: InputMaybe<Array<OrderType>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Posting = {
  __typename?: 'Posting';
  cost: Scalars['Float'];
  effectiveDate: Scalars['DateTime'];
  effectiveEndDate: Scalars['DateTime'];
  orders?: Maybe<Array<Maybe<Order>>>;
  postingKey: Scalars['Int'];
  supplier: Supplier;
  supplierKey: Scalars['Int'];
  terminal: Terminal;
  terminalKey: Scalars['Int'];
};

export type PostingFilterInput = {
  and?: InputMaybe<Array<PostingFilterInput>>;
  cost?: InputMaybe<ComparableDoubleOperationFilterInput>;
  effectiveDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  effectiveEndDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PostingFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  postingKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type PostingSortInput = {
  cost?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  effectiveEndDate?: InputMaybe<SortEnumType>;
  postingKey?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SupplierSortInput>;
  supplierKey?: InputMaybe<SortEnumType>;
  terminal?: InputMaybe<TerminalSortInput>;
  terminalKey?: InputMaybe<SortEnumType>;
};

export type PrePurchaseAgreement = {
  __typename?: 'PrePurchaseAgreement';
  aprilEstimate: Scalars['Float'];
  aprilPriceIncrement: Scalars['Float'];
  aprilSellBack: Scalars['Float'];
  assessmentCost: Scalars['Float'];
  augustEstimate: Scalars['Float'];
  augustPriceIncrement: Scalars['Float'];
  augustSellBack: Scalars['Float'];
  chargeTariff: Scalars['Boolean'];
  costDescription?: Maybe<Scalars['String']>;
  decemberEstimate: Scalars['Float'];
  decemberPriceIncrement: Scalars['Float'];
  decemberSellBack: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  endShipDate: Scalars['DateTime'];
  februaryEstimate: Scalars['Float'];
  februaryPriceIncrement: Scalars['Float'];
  februarySellBack: Scalars['Float'];
  freightCost: Scalars['Float'];
  gallons: Scalars['Float'];
  includeFreight: Scalars['Boolean'];
  includeInTermContractVolume: Scalars['Boolean'];
  januaryEstimate: Scalars['Float'];
  januaryPriceIncrement: Scalars['Float'];
  januarySellBack: Scalars['Float'];
  julyEstimate: Scalars['Float'];
  julyPriceIncrement: Scalars['Float'];
  julySellBack: Scalars['Float'];
  juneEstimate: Scalars['Float'];
  junePriceIncrement: Scalars['Float'];
  juneSellBack: Scalars['Float'];
  marchEstimate: Scalars['Float'];
  marchPriceIncrement: Scalars['Float'];
  marchSellBack: Scalars['Float'];
  mayEstimate: Scalars['Float'];
  mayPriceIncrement: Scalars['Float'];
  maySellBack: Scalars['Float'];
  miscCost: Scalars['Float'];
  novemberEstimate: Scalars['Float'];
  novemberPriceIncrement: Scalars['Float'];
  novemberSellBack: Scalars['Float'];
  octoberEstimate: Scalars['Float'];
  octoberPriceIncrement: Scalars['Float'];
  octoberSellBack: Scalars['Float'];
  orders?: Maybe<Array<Maybe<Order>>>;
  otherCosts: Scalars['Float'];
  prePaidDollars: Scalars['Float'];
  prePurchaseAgreementKey: Scalars['Int'];
  prePurchaseAgreementSalesAgreements?: Maybe<Array<PrePurchaseAgreementSalesAgreement>>;
  productCost: Scalars['Float'];
  septemberEstimate: Scalars['Float'];
  septemberPriceIncrement: Scalars['Float'];
  septemberSellBack: Scalars['Float'];
  showOnPricing: Scalars['Boolean'];
  startShipDate: Scalars['DateTime'];
  supplier: Supplier;
  supplierAgreement?: Maybe<Scalars['String']>;
  supplierKey: Scalars['Int'];
  termContractPrePurchaseAgreements?: Maybe<Array<TermContractPrePurchaseAgreement>>;
  terminal: Terminal;
  terminalKey: Scalars['Int'];
  toDateGallons: Scalars['Float'];
  units: Scalars['Float'];
};

export type PrePurchaseAgreementFilterInput = {
  and?: InputMaybe<Array<PrePurchaseAgreementFilterInput>>;
  aprilEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  aprilPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  aprilSellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  assessmentCost?: InputMaybe<ComparableDoubleOperationFilterInput>;
  augustEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  augustPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  augustSellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  chargeTariff?: InputMaybe<BooleanOperationFilterInput>;
  costDescription?: InputMaybe<StringOperationFilterInput>;
  decemberEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  decemberPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  decemberSellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  endShipDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  februaryEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  februaryPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  februarySellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  freightCost?: InputMaybe<ComparableDoubleOperationFilterInput>;
  gallons?: InputMaybe<ComparableDoubleOperationFilterInput>;
  includeFreight?: InputMaybe<BooleanOperationFilterInput>;
  includeInTermContractVolume?: InputMaybe<BooleanOperationFilterInput>;
  januaryEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  januaryPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  januarySellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  julyEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  julyPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  julySellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  juneEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  junePriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  juneSellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  marchEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  marchPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  marchSellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  mayEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  mayPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  maySellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  miscCost?: InputMaybe<ComparableDoubleOperationFilterInput>;
  novemberEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  novemberPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  novemberSellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  octoberEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  octoberPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  octoberSellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<PrePurchaseAgreementFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  otherCosts?: InputMaybe<ComparableDoubleOperationFilterInput>;
  prePaidDollars?: InputMaybe<ComparableDoubleOperationFilterInput>;
  prePurchaseAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  prePurchaseAgreementSalesAgreements?: InputMaybe<ListFilterInputTypeOfPrePurchaseAgreementSalesAgreementFilterInput>;
  productCost?: InputMaybe<ComparableDoubleOperationFilterInput>;
  septemberEstimate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  septemberPriceIncrement?: InputMaybe<ComparableDoubleOperationFilterInput>;
  septemberSellBack?: InputMaybe<ComparableDoubleOperationFilterInput>;
  showOnPricing?: InputMaybe<BooleanOperationFilterInput>;
  startShipDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierAgreement?: InputMaybe<StringOperationFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  termContractPrePurchaseAgreements?: InputMaybe<ListFilterInputTypeOfTermContractPrePurchaseAgreementFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  toDateGallons?: InputMaybe<ComparableDoubleOperationFilterInput>;
  units?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type PrePurchaseAgreementSalesAgreement = {
  __typename?: 'PrePurchaseAgreementSalesAgreement';
  prePurchaseAgreement: PrePurchaseAgreement;
  prePurchaseAgreementKey: Scalars['Int'];
  salesAgreement: SalesAgreement;
  salesAgreementKey: Scalars['Int'];
};

export type PrePurchaseAgreementSalesAgreementFilterInput = {
  and?: InputMaybe<Array<PrePurchaseAgreementSalesAgreementFilterInput>>;
  or?: InputMaybe<Array<PrePurchaseAgreementSalesAgreementFilterInput>>;
  prePurchaseAgreement?: InputMaybe<PrePurchaseAgreementFilterInput>;
  prePurchaseAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  salesAgreement?: InputMaybe<SalesAgreementFilterInput>;
  salesAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type PrePurchaseAgreementSortInput = {
  aprilEstimate?: InputMaybe<SortEnumType>;
  aprilPriceIncrement?: InputMaybe<SortEnumType>;
  aprilSellBack?: InputMaybe<SortEnumType>;
  assessmentCost?: InputMaybe<SortEnumType>;
  augustEstimate?: InputMaybe<SortEnumType>;
  augustPriceIncrement?: InputMaybe<SortEnumType>;
  augustSellBack?: InputMaybe<SortEnumType>;
  chargeTariff?: InputMaybe<SortEnumType>;
  costDescription?: InputMaybe<SortEnumType>;
  decemberEstimate?: InputMaybe<SortEnumType>;
  decemberPriceIncrement?: InputMaybe<SortEnumType>;
  decemberSellBack?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  endShipDate?: InputMaybe<SortEnumType>;
  februaryEstimate?: InputMaybe<SortEnumType>;
  februaryPriceIncrement?: InputMaybe<SortEnumType>;
  februarySellBack?: InputMaybe<SortEnumType>;
  freightCost?: InputMaybe<SortEnumType>;
  gallons?: InputMaybe<SortEnumType>;
  includeFreight?: InputMaybe<SortEnumType>;
  includeInTermContractVolume?: InputMaybe<SortEnumType>;
  januaryEstimate?: InputMaybe<SortEnumType>;
  januaryPriceIncrement?: InputMaybe<SortEnumType>;
  januarySellBack?: InputMaybe<SortEnumType>;
  julyEstimate?: InputMaybe<SortEnumType>;
  julyPriceIncrement?: InputMaybe<SortEnumType>;
  julySellBack?: InputMaybe<SortEnumType>;
  juneEstimate?: InputMaybe<SortEnumType>;
  junePriceIncrement?: InputMaybe<SortEnumType>;
  juneSellBack?: InputMaybe<SortEnumType>;
  marchEstimate?: InputMaybe<SortEnumType>;
  marchPriceIncrement?: InputMaybe<SortEnumType>;
  marchSellBack?: InputMaybe<SortEnumType>;
  mayEstimate?: InputMaybe<SortEnumType>;
  mayPriceIncrement?: InputMaybe<SortEnumType>;
  maySellBack?: InputMaybe<SortEnumType>;
  miscCost?: InputMaybe<SortEnumType>;
  novemberEstimate?: InputMaybe<SortEnumType>;
  novemberPriceIncrement?: InputMaybe<SortEnumType>;
  novemberSellBack?: InputMaybe<SortEnumType>;
  octoberEstimate?: InputMaybe<SortEnumType>;
  octoberPriceIncrement?: InputMaybe<SortEnumType>;
  octoberSellBack?: InputMaybe<SortEnumType>;
  otherCosts?: InputMaybe<SortEnumType>;
  prePaidDollars?: InputMaybe<SortEnumType>;
  prePurchaseAgreementKey?: InputMaybe<SortEnumType>;
  productCost?: InputMaybe<SortEnumType>;
  septemberEstimate?: InputMaybe<SortEnumType>;
  septemberPriceIncrement?: InputMaybe<SortEnumType>;
  septemberSellBack?: InputMaybe<SortEnumType>;
  showOnPricing?: InputMaybe<SortEnumType>;
  startShipDate?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SupplierSortInput>;
  supplierAgreement?: InputMaybe<SortEnumType>;
  supplierKey?: InputMaybe<SortEnumType>;
  terminal?: InputMaybe<TerminalSortInput>;
  terminalKey?: InputMaybe<SortEnumType>;
  toDateGallons?: InputMaybe<SortEnumType>;
  units?: InputMaybe<SortEnumType>;
};

export type Query = {
  __typename?: 'Query';
  currentUser: User;
  orderGetAll?: Maybe<OrderGetAllConnection>;
  orderGetForTripsDashboard?: Maybe<OrderGetForTripsDashboardConnection>;
};


export type QueryOrderGetAllArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<OrderSortInput>>;
  where?: InputMaybe<OrderFilterInput>;
};


export type QueryOrderGetForTripsDashboardArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<OrderSortInput>>;
  where?: InputMaybe<OrderFilterInput>;
};

export type RefreshTokenFilterInput = {
  and?: InputMaybe<Array<RefreshTokenFilterInput>>;
  expirationDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<RefreshTokenFilterInput>>;
  refreshTokenKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  token?: InputMaybe<StringOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type SalesAgreement = {
  __typename?: 'SalesAgreement';
  agreementName?: Maybe<Scalars['String']>;
  aprEst: Scalars['Float'];
  aprPI: Scalars['Float'];
  assessment: Scalars['Float'];
  augEst: Scalars['Float'];
  augPI: Scalars['Float'];
  decEst: Scalars['Float'];
  decPI: Scalars['Float'];
  deposit: Scalars['Float'];
  endShipDate: Scalars['DateTime'];
  febEst: Scalars['Float'];
  febPI: Scalars['Float'];
  gallons: Scalars['Int'];
  gallonsToDate: Scalars['Int'];
  janEst: Scalars['Float'];
  janPI: Scalars['Float'];
  julEst: Scalars['Float'];
  julPI: Scalars['Float'];
  junEst: Scalars['Float'];
  junPI: Scalars['Float'];
  loadsToDate: Scalars['Int'];
  marEst: Scalars['Float'];
  marPI: Scalars['Float'];
  mayEst: Scalars['Float'];
  mayPI: Scalars['Float'];
  misc?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  novEst: Scalars['Float'];
  novPI: Scalars['Float'];
  octEst: Scalars['Float'];
  octPI: Scalars['Float'];
  orders?: Maybe<Array<Maybe<Order>>>;
  prePaid: Scalars['Float'];
  prePurchaseAgreementSalesAgreements?: Maybe<Array<PrePurchaseAgreementSalesAgreement>>;
  quoted?: Maybe<Scalars['Float']>;
  quotedFOB?: Maybe<Scalars['Float']>;
  relatedFromSalesAgreements?: Maybe<Array<LinkedSalesAgreement>>;
  relatedToSalesAgreements?: Maybe<Array<Maybe<LinkedSalesAgreement>>>;
  salesAgreementKey: Scalars['Int'];
  salesAgreementLocations?: Maybe<Array<SalesAgreementLocation>>;
  sepEst: Scalars['Float'];
  sepPI: Scalars['Float'];
  startShipDate: Scalars['DateTime'];
  supplier?: Maybe<Supplier>;
  supplierKey?: Maybe<Scalars['Int']>;
  swapDealSalesAgreements?: Maybe<Array<Maybe<SwapDealSalesAgreement>>>;
  termContractSalesAgreements?: Maybe<Array<TermContractSalesAgreement>>;
  terminal?: Maybe<Terminal>;
  terminalKey?: Maybe<Scalars['Int']>;
};

export type SalesAgreementFilterInput = {
  agreementName?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<SalesAgreementFilterInput>>;
  aprEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  aprPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  assessment?: InputMaybe<ComparableDoubleOperationFilterInput>;
  augEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  augPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  decEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  decPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  deposit?: InputMaybe<ComparableDoubleOperationFilterInput>;
  endShipDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  febEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  febPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  gallons?: InputMaybe<ComparableInt32OperationFilterInput>;
  gallonsToDate?: InputMaybe<ComparableInt32OperationFilterInput>;
  janEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  janPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  julEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  julPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  junEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  junPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  loadsToDate?: InputMaybe<ComparableInt32OperationFilterInput>;
  marEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  marPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  mayEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  mayPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  misc?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  novEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  novPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  octEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  octPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<SalesAgreementFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  prePaid?: InputMaybe<ComparableDoubleOperationFilterInput>;
  prePurchaseAgreementSalesAgreements?: InputMaybe<ListFilterInputTypeOfPrePurchaseAgreementSalesAgreementFilterInput>;
  quoted?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  quotedFOB?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  relatedFromSalesAgreements?: InputMaybe<ListFilterInputTypeOfLinkedSalesAgreementFilterInput>;
  relatedToSalesAgreements?: InputMaybe<ListFilterInputTypeOfLinkedSalesAgreementFilterInput>;
  salesAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  salesAgreementLocations?: InputMaybe<ListFilterInputTypeOfSalesAgreementLocationFilterInput>;
  sepEst?: InputMaybe<ComparableDoubleOperationFilterInput>;
  sepPI?: InputMaybe<ComparableDoubleOperationFilterInput>;
  startShipDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  swapDealSalesAgreements?: InputMaybe<ListFilterInputTypeOfSwapDealSalesAgreementFilterInput>;
  termContractSalesAgreements?: InputMaybe<ListFilterInputTypeOfTermContractSalesAgreementFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type SalesAgreementLocation = {
  __typename?: 'SalesAgreementLocation';
  location: Location;
  locationKey: Scalars['Int'];
  salesAgreement: SalesAgreement;
  salesAgreementKey: Scalars['Int'];
};

export type SalesAgreementLocationFilterInput = {
  and?: InputMaybe<Array<SalesAgreementLocationFilterInput>>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<SalesAgreementLocationFilterInput>>;
  salesAgreement?: InputMaybe<SalesAgreementFilterInput>;
  salesAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type SalesAgreementSortInput = {
  agreementName?: InputMaybe<SortEnumType>;
  aprEst?: InputMaybe<SortEnumType>;
  aprPI?: InputMaybe<SortEnumType>;
  assessment?: InputMaybe<SortEnumType>;
  augEst?: InputMaybe<SortEnumType>;
  augPI?: InputMaybe<SortEnumType>;
  decEst?: InputMaybe<SortEnumType>;
  decPI?: InputMaybe<SortEnumType>;
  deposit?: InputMaybe<SortEnumType>;
  endShipDate?: InputMaybe<SortEnumType>;
  febEst?: InputMaybe<SortEnumType>;
  febPI?: InputMaybe<SortEnumType>;
  gallons?: InputMaybe<SortEnumType>;
  gallonsToDate?: InputMaybe<SortEnumType>;
  janEst?: InputMaybe<SortEnumType>;
  janPI?: InputMaybe<SortEnumType>;
  julEst?: InputMaybe<SortEnumType>;
  julPI?: InputMaybe<SortEnumType>;
  junEst?: InputMaybe<SortEnumType>;
  junPI?: InputMaybe<SortEnumType>;
  loadsToDate?: InputMaybe<SortEnumType>;
  marEst?: InputMaybe<SortEnumType>;
  marPI?: InputMaybe<SortEnumType>;
  mayEst?: InputMaybe<SortEnumType>;
  mayPI?: InputMaybe<SortEnumType>;
  misc?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  novEst?: InputMaybe<SortEnumType>;
  novPI?: InputMaybe<SortEnumType>;
  octEst?: InputMaybe<SortEnumType>;
  octPI?: InputMaybe<SortEnumType>;
  prePaid?: InputMaybe<SortEnumType>;
  quoted?: InputMaybe<SortEnumType>;
  quotedFOB?: InputMaybe<SortEnumType>;
  salesAgreementKey?: InputMaybe<SortEnumType>;
  sepEst?: InputMaybe<SortEnumType>;
  sepPI?: InputMaybe<SortEnumType>;
  startShipDate?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SupplierSortInput>;
  supplierKey?: InputMaybe<SortEnumType>;
  terminal?: InputMaybe<TerminalSortInput>;
  terminalKey?: InputMaybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type State = {
  __typename?: 'State';
  cities?: Maybe<Array<Maybe<City>>>;
  name?: Maybe<Scalars['String']>;
  stateKey: Scalars['Int'];
  stateTaxes?: Maybe<Array<Maybe<StateTax>>>;
};

export type StateFilterInput = {
  and?: InputMaybe<Array<StateFilterInput>>;
  cities?: InputMaybe<ListFilterInputTypeOfCityFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StateFilterInput>>;
  stateKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  stateTaxes?: InputMaybe<ListFilterInputTypeOfStateTaxFilterInput>;
};

export type StateTax = {
  __typename?: 'StateTax';
  amount: Scalars['Float'];
  endDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  state?: Maybe<State>;
  stateKey: Scalars['Int'];
  stateTaxKey: Scalars['Int'];
  stateTaxType: StateTaxType;
};

export type StateTaxFilterInput = {
  amount?: InputMaybe<ComparableDoubleOperationFilterInput>;
  and?: InputMaybe<Array<StateTaxFilterInput>>;
  endDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<StateTaxFilterInput>>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  state?: InputMaybe<StateFilterInput>;
  stateKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  stateTaxKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  stateTaxType?: InputMaybe<StateTaxTypeOperationFilterInput>;
};

export enum StateTaxType {
  Assessment = 'ASSESSMENT',
  Tax = 'TAX'
}

export type StateTaxTypeOperationFilterInput = {
  eq?: InputMaybe<StateTaxType>;
  in?: InputMaybe<Array<StateTaxType>>;
  neq?: InputMaybe<StateTaxType>;
  nin?: InputMaybe<Array<StateTaxType>>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  contractVolumes?: Maybe<Array<Maybe<ContractVolume>>>;
  directMarkups?: Maybe<Array<Maybe<DirectMarkup>>>;
  directions?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  fileNumbers?: Maybe<Array<Maybe<FileNumber>>>;
  indexContracts?: Maybe<Array<Maybe<IndexContract>>>;
  isArchived: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  phone?: Maybe<Scalars['String']>;
  postings?: Maybe<Array<Maybe<Posting>>>;
  prePurchaseAgreements?: Maybe<Array<Maybe<PrePurchaseAgreement>>>;
  salesAgreements?: Maybe<Array<Maybe<SalesAgreement>>>;
  state?: Maybe<Scalars['String']>;
  supplierKey: Scalars['Int'];
  termContractSuppliers?: Maybe<Array<Maybe<TermContractSupplier>>>;
  termContracts?: Maybe<Array<Maybe<TermContract>>>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SupplierFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<SupplierFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  contacts?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  contractVolumes?: InputMaybe<ListFilterInputTypeOfContractVolumeFilterInput>;
  directMarkups?: InputMaybe<ListFilterInputTypeOfDirectMarkupFilterInput>;
  directions?: InputMaybe<StringOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  fileNumbers?: InputMaybe<ListFilterInputTypeOfFileNumberFilterInput>;
  indexContracts?: InputMaybe<ListFilterInputTypeOfIndexContractFilterInput>;
  isArchived?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SupplierFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  postings?: InputMaybe<ListFilterInputTypeOfPostingFilterInput>;
  prePurchaseAgreements?: InputMaybe<ListFilterInputTypeOfPrePurchaseAgreementFilterInput>;
  salesAgreements?: InputMaybe<ListFilterInputTypeOfSalesAgreementFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  termContractSuppliers?: InputMaybe<ListFilterInputTypeOfTermContractSupplierFilterInput>;
  termContracts?: InputMaybe<ListFilterInputTypeOfTermContractFilterInput>;
  zipCode?: InputMaybe<StringOperationFilterInput>;
};

export type SupplierSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  directions?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  isArchived?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  supplierKey?: InputMaybe<SortEnumType>;
  zipCode?: InputMaybe<SortEnumType>;
};

export enum SupplierType {
  Cavern = 'CAVERN',
  GasProcessingPlant = 'GAS_PROCESSING_PLANT',
  Pipeline = 'PIPELINE',
  RailStorage = 'RAIL_STORAGE',
  Refinery = 'REFINERY',
  StorageFacility = 'STORAGE_FACILITY'
}

export type SupplierTypeOperationFilterInput = {
  eq?: InputMaybe<SupplierType>;
  in?: InputMaybe<Array<SupplierType>>;
  neq?: InputMaybe<SupplierType>;
  nin?: InputMaybe<Array<SupplierType>>;
};

export type SwapDeal = {
  __typename?: 'SwapDeal';
  basisType?: Maybe<BasisType>;
  basisTypeKey: Scalars['Int'];
  dealNumber: Scalars['Int'];
  effectiveEndDate: Scalars['DateTime'];
  effectiveStartDate: Scalars['DateTime'];
  monthlyVolume: Scalars['Int'];
  sglCost: Scalars['Float'];
  swapDealKey: Scalars['Int'];
  swapDealSalesAgreements?: Maybe<Array<Maybe<SwapDealSalesAgreement>>>;
};

export type SwapDealFilterInput = {
  and?: InputMaybe<Array<SwapDealFilterInput>>;
  basisType?: InputMaybe<BasisTypeFilterInput>;
  basisTypeKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  dealNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  effectiveEndDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  effectiveStartDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  monthlyVolume?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<SwapDealFilterInput>>;
  sglCost?: InputMaybe<ComparableDoubleOperationFilterInput>;
  swapDealKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  swapDealSalesAgreements?: InputMaybe<ListFilterInputTypeOfSwapDealSalesAgreementFilterInput>;
};

export type SwapDealSalesAgreement = {
  __typename?: 'SwapDealSalesAgreement';
  salesAgreement?: Maybe<SalesAgreement>;
  salesAgreementKey: Scalars['Int'];
  swapDeal?: Maybe<SwapDeal>;
  swapDealKey: Scalars['Int'];
};

export type SwapDealSalesAgreementFilterInput = {
  and?: InputMaybe<Array<SwapDealSalesAgreementFilterInput>>;
  or?: InputMaybe<Array<SwapDealSalesAgreementFilterInput>>;
  salesAgreement?: InputMaybe<SalesAgreementFilterInput>;
  salesAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  swapDeal?: InputMaybe<SwapDealFilterInput>;
  swapDealKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type Tag = {
  __typename?: 'Tag';
  customerTag?: Maybe<CustomerTag>;
  customerTags?: Maybe<Array<Maybe<CustomerTag>>>;
  locationTag?: Maybe<LocationTag>;
  locationTags?: Maybe<Array<Maybe<LocationTag>>>;
  name?: Maybe<Scalars['String']>;
  tagKey: Scalars['Int'];
};

export type TagFilterInput = {
  and?: InputMaybe<Array<TagFilterInput>>;
  customerTags?: InputMaybe<ListFilterInputTypeOfCustomerTagFilterInput>;
  locationTags?: InputMaybe<ListFilterInputTypeOfLocationTagFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TagFilterInput>>;
  tagKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type Tank = {
  __typename?: 'Tank';
  location?: Maybe<Location>;
  locationKey: Scalars['Int'];
  size: Scalars['Int'];
  tankKey: Scalars['Int'];
};

export type TankFilterInput = {
  and?: InputMaybe<Array<TankFilterInput>>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<TankFilterInput>>;
  size?: InputMaybe<ComparableInt32OperationFilterInput>;
  tankKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type Task = {
  __typename?: 'Task';
  callDetails?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['DateTime']>;
  contact: Contact;
  contactKey: Scalars['Int'];
  contactMethod: ContactMethod;
  isComplete: Scalars['Boolean'];
  location: Location;
  locationKey: Scalars['Int'];
  taskDate: Scalars['DateTime'];
  taskKey: Scalars['Int'];
  user: User;
  userKey: Scalars['Int'];
};

export type TaskFilterInput = {
  and?: InputMaybe<Array<TaskFilterInput>>;
  callDetails?: InputMaybe<StringOperationFilterInput>;
  completedDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  contactKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  contactMethod?: InputMaybe<ContactMethodOperationFilterInput>;
  isComplete?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<TaskFilterInput>>;
  taskDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  taskKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type TermContract = {
  __typename?: 'TermContract';
  april: Scalars['Float'];
  august: Scalars['Float'];
  december: Scalars['Float'];
  endDate: Scalars['DateTime'];
  february: Scalars['Float'];
  indexContracts: Scalars['Boolean'];
  january: Scalars['Float'];
  july: Scalars['Float'];
  june: Scalars['Float'];
  march: Scalars['Float'];
  may: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  november: Scalars['Float'];
  october: Scalars['Float'];
  orders?: Maybe<Array<Maybe<Order>>>;
  posts: Scalars['Boolean'];
  september: Scalars['Float'];
  startDate: Scalars['DateTime'];
  termContractKey: Scalars['Int'];
  termContractLocations?: Maybe<Array<Maybe<TermContractLocation>>>;
  termContractPrePurchaseAgreements?: Maybe<Array<Maybe<TermContractPrePurchaseAgreement>>>;
  termContractSalesAgreements?: Maybe<Array<Maybe<TermContractSalesAgreement>>>;
  termContractSuppliers?: Maybe<Array<Maybe<TermContractSupplier>>>;
  termContractTerminals?: Maybe<Array<Maybe<TermContractTerminal>>>;
};

export type TermContractFilterInput = {
  and?: InputMaybe<Array<TermContractFilterInput>>;
  april?: InputMaybe<ComparableDoubleOperationFilterInput>;
  august?: InputMaybe<ComparableDoubleOperationFilterInput>;
  december?: InputMaybe<ComparableDoubleOperationFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  february?: InputMaybe<ComparableDoubleOperationFilterInput>;
  indexContracts?: InputMaybe<BooleanOperationFilterInput>;
  january?: InputMaybe<ComparableDoubleOperationFilterInput>;
  july?: InputMaybe<ComparableDoubleOperationFilterInput>;
  june?: InputMaybe<ComparableDoubleOperationFilterInput>;
  march?: InputMaybe<ComparableDoubleOperationFilterInput>;
  may?: InputMaybe<ComparableDoubleOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  november?: InputMaybe<ComparableDoubleOperationFilterInput>;
  october?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<TermContractFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  posts?: InputMaybe<BooleanOperationFilterInput>;
  september?: InputMaybe<ComparableDoubleOperationFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  termContractKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  termContractLocations?: InputMaybe<ListFilterInputTypeOfTermContractLocationFilterInput>;
  termContractPrePurchaseAgreements?: InputMaybe<ListFilterInputTypeOfTermContractPrePurchaseAgreementFilterInput>;
  termContractSalesAgreements?: InputMaybe<ListFilterInputTypeOfTermContractSalesAgreementFilterInput>;
  termContractSuppliers?: InputMaybe<ListFilterInputTypeOfTermContractSupplierFilterInput>;
  termContractTerminals?: InputMaybe<ListFilterInputTypeOfTermContractTerminalFilterInput>;
};

export type TermContractLocation = {
  __typename?: 'TermContractLocation';
  location?: Maybe<Location>;
  locationKey: Scalars['Int'];
  termContract?: Maybe<TermContract>;
  termContractKey: Scalars['Int'];
};

export type TermContractLocationFilterInput = {
  and?: InputMaybe<Array<TermContractLocationFilterInput>>;
  location?: InputMaybe<LocationFilterInput>;
  locationKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<TermContractLocationFilterInput>>;
  termContract?: InputMaybe<TermContractFilterInput>;
  termContractKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type TermContractPrePurchaseAgreement = {
  __typename?: 'TermContractPrePurchaseAgreement';
  prePurchaseAgreement?: Maybe<PrePurchaseAgreement>;
  prePurchaseAgreementKey: Scalars['Int'];
  termContract?: Maybe<TermContract>;
  termContractKey: Scalars['Int'];
};

export type TermContractPrePurchaseAgreementFilterInput = {
  and?: InputMaybe<Array<TermContractPrePurchaseAgreementFilterInput>>;
  or?: InputMaybe<Array<TermContractPrePurchaseAgreementFilterInput>>;
  prePurchaseAgreement?: InputMaybe<PrePurchaseAgreementFilterInput>;
  prePurchaseAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  termContract?: InputMaybe<TermContractFilterInput>;
  termContractKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type TermContractSalesAgreement = {
  __typename?: 'TermContractSalesAgreement';
  salesAgreement?: Maybe<SalesAgreement>;
  salesAgreementKey: Scalars['Int'];
  termContract?: Maybe<TermContract>;
  termContractKey: Scalars['Int'];
};

export type TermContractSalesAgreementFilterInput = {
  and?: InputMaybe<Array<TermContractSalesAgreementFilterInput>>;
  or?: InputMaybe<Array<TermContractSalesAgreementFilterInput>>;
  salesAgreement?: InputMaybe<SalesAgreementFilterInput>;
  salesAgreementKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  termContract?: InputMaybe<TermContractFilterInput>;
  termContractKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type TermContractSortInput = {
  april?: InputMaybe<SortEnumType>;
  august?: InputMaybe<SortEnumType>;
  december?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  february?: InputMaybe<SortEnumType>;
  indexContracts?: InputMaybe<SortEnumType>;
  january?: InputMaybe<SortEnumType>;
  july?: InputMaybe<SortEnumType>;
  june?: InputMaybe<SortEnumType>;
  march?: InputMaybe<SortEnumType>;
  may?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  november?: InputMaybe<SortEnumType>;
  october?: InputMaybe<SortEnumType>;
  posts?: InputMaybe<SortEnumType>;
  september?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  termContractKey?: InputMaybe<SortEnumType>;
};

export type TermContractSupplier = {
  __typename?: 'TermContractSupplier';
  supplier?: Maybe<Supplier>;
  supplierKey: Scalars['Int'];
  termContract?: Maybe<TermContract>;
  termContractKey: Scalars['Int'];
};

export type TermContractSupplierFilterInput = {
  and?: InputMaybe<Array<TermContractSupplierFilterInput>>;
  or?: InputMaybe<Array<TermContractSupplierFilterInput>>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  termContract?: InputMaybe<TermContractFilterInput>;
  termContractKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type TermContractTerminal = {
  __typename?: 'TermContractTerminal';
  termContract?: Maybe<TermContract>;
  termContractKey: Scalars['Int'];
  terminal?: Maybe<Terminal>;
  terminalKey: Scalars['Int'];
};

export type TermContractTerminalFilterInput = {
  and?: InputMaybe<Array<TermContractTerminalFilterInput>>;
  or?: InputMaybe<Array<TermContractTerminalFilterInput>>;
  termContract?: InputMaybe<TermContractFilterInput>;
  termContractKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  terminal?: InputMaybe<TerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type Terminal = {
  __typename?: 'Terminal';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  contractVolumeTerminals?: Maybe<Array<Maybe<ContractVolumeTerminal>>>;
  directMarkups?: Maybe<Array<Maybe<DirectMarkup>>>;
  directions?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  fileNumbers?: Maybe<Array<Maybe<FileNumber>>>;
  indexContracts?: Maybe<Array<Maybe<IndexContract>>>;
  isArchived: Scalars['Boolean'];
  locationTerminals?: Maybe<Array<Maybe<LocationTerminal>>>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  phone?: Maybe<Scalars['String']>;
  postings?: Maybe<Array<Maybe<Posting>>>;
  prePurchaseAgreements?: Maybe<Array<Maybe<PrePurchaseAgreement>>>;
  salesAgreements?: Maybe<Array<Maybe<SalesAgreement>>>;
  state?: Maybe<Scalars['String']>;
  termContractTerminals?: Maybe<Array<Maybe<TermContractTerminal>>>;
  terminalKey: Scalars['Int'];
  zipCode?: Maybe<Scalars['String']>;
};

export type TerminalFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<TerminalFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  contacts?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  contractVolumeTerminals?: InputMaybe<ListFilterInputTypeOfContractVolumeTerminalFilterInput>;
  directMarkups?: InputMaybe<ListFilterInputTypeOfDirectMarkupFilterInput>;
  directions?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  fileNumbers?: InputMaybe<ListFilterInputTypeOfFileNumberFilterInput>;
  indexContracts?: InputMaybe<ListFilterInputTypeOfIndexContractFilterInput>;
  isArchived?: InputMaybe<BooleanOperationFilterInput>;
  locationTerminals?: InputMaybe<ListFilterInputTypeOfLocationTerminalFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TerminalFilterInput>>;
  orders?: InputMaybe<ListFilterInputTypeOfOrderFilterInput>;
  phone?: InputMaybe<StringOperationFilterInput>;
  postings?: InputMaybe<ListFilterInputTypeOfPostingFilterInput>;
  prePurchaseAgreements?: InputMaybe<ListFilterInputTypeOfPrePurchaseAgreementFilterInput>;
  salesAgreements?: InputMaybe<ListFilterInputTypeOfSalesAgreementFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  termContractTerminals?: InputMaybe<ListFilterInputTypeOfTermContractTerminalFilterInput>;
  terminalKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  zipCode?: InputMaybe<StringOperationFilterInput>;
};

export type TerminalSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  directions?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  isArchived?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  terminalKey?: InputMaybe<SortEnumType>;
  zipCode?: InputMaybe<SortEnumType>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  locations?: Maybe<Array<Maybe<Location>>>;
  lockoutEnabled: Scalars['Boolean'];
  lockoutEnd?: Maybe<Scalars['DateTime']>;
  marketingUser: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferPrefixSearch: Scalars['Boolean'];
  tasks?: Maybe<Array<Maybe<Task>>>;
  userCarriers?: Maybe<Array<Maybe<UserCarrier>>>;
  userName?: Maybe<Scalars['String']>;
};

export type UserCarrier = {
  __typename?: 'UserCarrier';
  carrier?: Maybe<Carrier>;
  carrierKey: Scalars['Int'];
  user?: Maybe<User>;
  userKey: Scalars['Int'];
};

export type UserCarrierFilterInput = {
  and?: InputMaybe<Array<UserCarrierFilterInput>>;
  carrier?: InputMaybe<CarrierFilterInput>;
  carrierKey?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<UserCarrierFilterInput>>;
  user?: InputMaybe<UserFilterInput>;
  userKey?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type UserFilterInput = {
  accessFailedCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  and?: InputMaybe<Array<UserFilterInput>>;
  concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  locations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  lockoutEnabled?: InputMaybe<BooleanOperationFilterInput>;
  lockoutEnd?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  marketingUser?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedEmail?: InputMaybe<StringOperationFilterInput>;
  normalizedUserName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  passwordHash?: InputMaybe<StringOperationFilterInput>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  phoneNumberConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  preferPrefixSearch?: InputMaybe<BooleanOperationFilterInput>;
  refreshTokens?: InputMaybe<ListFilterInputTypeOfRefreshTokenFilterInput>;
  securityStamp?: InputMaybe<StringOperationFilterInput>;
  tasks?: InputMaybe<ListFilterInputTypeOfTaskFilterInput>;
  twoFactorEnabled?: InputMaybe<BooleanOperationFilterInput>;
  userCarriers?: InputMaybe<ListFilterInputTypeOfUserCarrierFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type UserSortInput = {
  accessFailedCount?: InputMaybe<SortEnumType>;
  concurrencyStamp?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lockoutEnabled?: InputMaybe<SortEnumType>;
  lockoutEnd?: InputMaybe<SortEnumType>;
  marketingUser?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedEmail?: InputMaybe<SortEnumType>;
  normalizedUserName?: InputMaybe<SortEnumType>;
  passwordHash?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  phoneNumberConfirmed?: InputMaybe<SortEnumType>;
  preferPrefixSearch?: InputMaybe<SortEnumType>;
  securityStamp?: InputMaybe<SortEnumType>;
  twoFactorEnabled?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type InnerOrderFragment = { __typename?: 'Order', orderKey: number, deliveryDate: any, tripNumber?: string | null, status: OrderStatus, actualGallons?: number | null, gallons: number, manifest?: string | null, location?: { __typename?: 'Location', name?: string | null, city?: string | null, state?: string | null } | null, customer?: { __typename?: 'Customer', name?: string | null } | null, supplier?: { __typename?: 'Supplier', name?: string | null } | null, terminal?: { __typename?: 'Terminal', name?: string | null } | null, carrier?: { __typename?: 'Carrier', carrierKey: number, name?: string | null } | null };

export type OrderTripsDashboardListingFragment = { __typename?: 'OrderGetForTripsDashboardConnection', totalCount: number, edges?: Array<{ __typename?: 'OrderGetForTripsDashboardEdge', cursor: string, node?: { __typename?: 'Order', orderKey: number, deliveryDate: any, tripNumber?: string | null, status: OrderStatus, actualGallons?: number | null, gallons: number, manifest?: string | null, location?: { __typename?: 'Location', name?: string | null, city?: string | null, state?: string | null } | null, customer?: { __typename?: 'Customer', name?: string | null } | null, supplier?: { __typename?: 'Supplier', name?: string | null } | null, terminal?: { __typename?: 'Terminal', name?: string | null } | null, carrier?: { __typename?: 'Carrier', carrierKey: number, name?: string | null } | null } | null }> | null };

export type InnerOrderListingFragment = { __typename?: 'OrderGetForTripsDashboardEdge', node?: { __typename?: 'Order', orderKey: number, deliveryDate: any, tripNumber?: string | null, status: OrderStatus, actualGallons?: number | null, gallons: number, manifest?: string | null, location?: { __typename?: 'Location', name?: string | null, city?: string | null, state?: string | null } | null, customer?: { __typename?: 'Customer', name?: string | null } | null, supplier?: { __typename?: 'Supplier', name?: string | null } | null, terminal?: { __typename?: 'Terminal', name?: string | null } | null, carrier?: { __typename?: 'Carrier', carrierKey: number, name?: string | null } | null } | null };

export type TripsOrderDashboardQueryVariables = Exact<{
  sort?: InputMaybe<Array<OrderSortInput> | OrderSortInput>;
  filter?: InputMaybe<OrderFilterInput>;
  cursor: Scalars['String'];
  pageSize: Scalars['Int'];
}>;


export type TripsOrderDashboardQuery = { __typename?: 'Query', orderGetForTripsDashboard?: { __typename?: 'OrderGetForTripsDashboardConnection', totalCount: number, edges?: Array<{ __typename?: 'OrderGetForTripsDashboardEdge', cursor: string, node?: { __typename?: 'Order', orderKey: number, deliveryDate: any, tripNumber?: string | null, status: OrderStatus, actualGallons?: number | null, gallons: number, manifest?: string | null, location?: { __typename?: 'Location', name?: string | null, city?: string | null, state?: string | null } | null, customer?: { __typename?: 'Customer', name?: string | null } | null, supplier?: { __typename?: 'Supplier', name?: string | null } | null, terminal?: { __typename?: 'Terminal', name?: string | null } | null, carrier?: { __typename?: 'Carrier', carrierKey: number, name?: string | null } | null } | null }> | null } | null };

export type TripsUpdateOrderMutationVariables = Exact<{
  orderKey: Scalars['Int'];
  manifest?: InputMaybe<Scalars['String']>;
  actualGallons: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
}>;


export type TripsUpdateOrderMutation = { __typename?: 'Mutation', orderTripsUpdate: boolean };

export const InnerOrderFragmentDoc = gql`
    fragment innerOrder on Order {
  orderKey
  location {
    name
    city
    state
  }
  customer {
    name
  }
  supplier {
    name
  }
  terminal {
    name
  }
  carrier {
    carrierKey
    name
  }
  deliveryDate
  tripNumber
  status
  actualGallons
  gallons
  manifest
}
    `;
export const InnerOrderListingFragmentDoc = gql`
    fragment innerOrderListing on OrderGetForTripsDashboardEdge {
  node {
    ...innerOrder
  }
}
    ${InnerOrderFragmentDoc}`;
export const OrderTripsDashboardListingFragmentDoc = gql`
    fragment orderTripsDashboardListing on OrderGetForTripsDashboardConnection {
  edges {
    cursor
    ...innerOrderListing
  }
  totalCount
}
    ${InnerOrderListingFragmentDoc}`;
export const TripsOrderDashboardDocument = gql`
    query tripsOrderDashboard($sort: [OrderSortInput!], $filter: OrderFilterInput, $cursor: String!, $pageSize: Int!) {
  orderGetForTripsDashboard(
    order: $sort
    where: $filter
    after: $cursor
    first: $pageSize
  ) {
    ...orderTripsDashboardListing
  }
}
    ${OrderTripsDashboardListingFragmentDoc}`;

/**
 * __useTripsOrderDashboardQuery__
 *
 * To run a query within a React component, call `useTripsOrderDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripsOrderDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripsOrderDashboardQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTripsOrderDashboardQuery(baseOptions: Apollo.QueryHookOptions<TripsOrderDashboardQuery, TripsOrderDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TripsOrderDashboardQuery, TripsOrderDashboardQueryVariables>(TripsOrderDashboardDocument, options);
      }
export function useTripsOrderDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TripsOrderDashboardQuery, TripsOrderDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TripsOrderDashboardQuery, TripsOrderDashboardQueryVariables>(TripsOrderDashboardDocument, options);
        }
export type TripsOrderDashboardQueryHookResult = ReturnType<typeof useTripsOrderDashboardQuery>;
export type TripsOrderDashboardLazyQueryHookResult = ReturnType<typeof useTripsOrderDashboardLazyQuery>;
export type TripsOrderDashboardQueryResult = Apollo.QueryResult<TripsOrderDashboardQuery, TripsOrderDashboardQueryVariables>;
export const TripsUpdateOrderDocument = gql`
    mutation TripsUpdateOrder($orderKey: Int!, $manifest: String, $actualGallons: Int!, $notes: String) {
  orderTripsUpdate(
    orderKey: $orderKey
    manifest: $manifest
    actualGallons: $actualGallons
    notes: $notes
  )
}
    `;
export type TripsUpdateOrderMutationFn = Apollo.MutationFunction<TripsUpdateOrderMutation, TripsUpdateOrderMutationVariables>;

/**
 * __useTripsUpdateOrderMutation__
 *
 * To run a mutation, you first call `useTripsUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTripsUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tripsUpdateOrderMutation, { data, loading, error }] = useTripsUpdateOrderMutation({
 *   variables: {
 *      orderKey: // value for 'orderKey'
 *      manifest: // value for 'manifest'
 *      actualGallons: // value for 'actualGallons'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useTripsUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<TripsUpdateOrderMutation, TripsUpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TripsUpdateOrderMutation, TripsUpdateOrderMutationVariables>(TripsUpdateOrderDocument, options);
      }
export type TripsUpdateOrderMutationHookResult = ReturnType<typeof useTripsUpdateOrderMutation>;
export type TripsUpdateOrderMutationResult = Apollo.MutationResult<TripsUpdateOrderMutation>;
export type TripsUpdateOrderMutationOptions = Apollo.BaseMutationOptions<TripsUpdateOrderMutation, TripsUpdateOrderMutationVariables>;