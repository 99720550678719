import React, { Component } from 'react'
import styles from './Sidebar.module.scss'

interface Props {
    content: React.ReactNode;
}
interface State {

}

export default class Sidebar extends Component<Props, State> {
    state = {}

    render() {
        return (
            <div className={styles.sidebar}>
                {this.props.content}
            </div>
        )
    }
}
