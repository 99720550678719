import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import styles from './App.module.scss'
import Home from './pages/Home'
import NavBar, { HamburgerState } from './components/NavBar/NavBar'
import SettingsPage from './pages/SettingsPage'
import Login from './pages/Login/Login'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import { UserLogin, login, LoginResponse } from './helpers/loginHelpers'
import { getAppInsights } from './plugins/telemetry/telemetryService'
import { TelemetryProvider } from './plugins/telemetry/telemetryProvider'

interface Props {

}

interface State {
    mainMenuIsOpen: boolean
    subMenuIsOpen: boolean
    lightHamburgerState: boolean
    isAuthenticated: boolean
}



export interface GlobalLoginProps {
    isAuthenticated: boolean
    login(userLogin: UserLogin): Promise<LoginResponse>
    logout()
}

export default class App extends React.Component<Props, State> {
    appInsights = null;

    state = {
        mainMenuIsOpen: false,
        subMenuIsOpen: false,
        lightHamburgerState: false,
        isAuthenticated: true,
    }

    componentDidMount() {
        const token = localStorage.getItem('token')
        this.setState({ isAuthenticated: !!token })
    }

    setMainMenuState(desiredState: boolean) {
        this.setState({ mainMenuIsOpen: desiredState })
    }

    setSubMenuState(desiredState: boolean) {
        this.setState({ subMenuIsOpen: desiredState })

        if (!desiredState) {
            this.setState({ lightHamburgerState: false })
        }
    }

    triggerLightMenuState() {
        this.setState({ lightHamburgerState: true })
    }

    login(userLogin: UserLogin): Promise<LoginResponse> {
        return login(userLogin)
            .then(data => {
                if (data.token) {
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('refreshToken', data.refreshToken)
                    this.setState({ isAuthenticated: true })
                }
                return data
            })
    }

    logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        this.setState({ isAuthenticated: false })
    }



    render() {
        const hamburgerState: HamburgerState = {
            setMainMenuState: this.setMainMenuState.bind(this),
            setSubMenuState: this.setSubMenuState.bind(this),
            triggerLightHamburger: this.triggerLightMenuState.bind(this),
            mainMenuState: this.state.mainMenuIsOpen,
            subMenuState: this.state.subMenuIsOpen,
            lightHamburgerState: this.state.lightHamburgerState
        }

        const globalLoginProps: GlobalLoginProps = {
            isAuthenticated: this.state.isAuthenticated,
            login: this.login.bind(this),
            logout: this.logout.bind(this)
        }

        return (
            <div className={styles.app}>
                <Router>
                    <TelemetryProvider instrumentationKey="cba2521b-3bcb-4d69-aafd-517974ee653e" after={() => { this.appInsights = getAppInsights() }}></TelemetryProvider>
                    {!!this.state.isAuthenticated && <NavBar hamburgerState={hamburgerState} />}

                    <Routes>
                        <Route path="/login" element={<Login {...globalLoginProps} />} />

                        <Route path="/" element={<PrivateRoute {...globalLoginProps}><Home /></PrivateRoute>} />

                        <Route path="/settings" element={<PrivateRoute {...globalLoginProps}><SettingsPage hamburgerState={hamburgerState} {...globalLoginProps} /></PrivateRoute>} />

                    </Routes>
                </Router>
            </div>
        )
    }
}