import React, { Component } from 'react'
import SubNav, { SubNavLink } from '../components/SubNav/SubNav'
import { HamburgerState } from '../components/NavBar/NavBar'
import { GlobalLoginProps } from '../App'

interface Props extends GlobalLoginProps {
    hamburgerState: HamburgerState
}
interface State {

}

class SettingsPage extends Component<Props, State> {
    state = {}

    render() {
        const linkList: SubNavLink[] = [
            { name: "Logout", action: () => this.props.logout() }
        ]

        return (
            <div style={{ display: "flex", width: "100%" }}>
                <SubNav
                    title="Settings"
                    links={linkList}
                    hamburgerState={this.props.hamburgerState}
                />
            </div>
        )
    }
}

export default SettingsPage
