import React, { ReactElement } from 'react'
import styles from './Tile.module.scss'

export enum TileColor {
    Green
}

interface Props {
    title?: string
    isFullWidth?: boolean
    tileColor?: TileColor
    headerButton?: ReactElement
    headerButtonCondition?: boolean

    children?: React.ReactNode
}

const Tile: React.FC<Props> = ({ title, isFullWidth, children, tileColor, headerButton, headerButtonCondition }) => {
    const colorClass = tileColor !== undefined ?
        tileColor === TileColor.Green ? styles.Tile__colorGreen :
            ''
        : ''

    const showHeaderButton = (headerButtonCondition === true || headerButtonCondition === false) ? headerButtonCondition : true

    return (
        <div className={`${styles.Tile} ${isFullWidth ? styles.Tile__fullWidth : ''} ${colorClass}`}>
            <div className={styles.Tile__header}>{title && <h3 className={styles.Tile__title}>{title}</h3>}{showHeaderButton && headerButton}</div>
            <div className={styles.Tile__content}>
                {children}
            </div>
        </div>
    )
}

export default Tile
