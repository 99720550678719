import React, { Component } from 'react'
import styles from './SubNav.module.scss'
import { NavLink } from 'react-router-dom'
import { HamburgerState } from '../NavBar/NavBar'

export interface SubNavLink {
    name: string
    link?: string
    action?()
}

interface Props {
    title: string
    links: SubNavLink[]
    hamburgerState: HamburgerState
}

interface State {
}

class SubNav extends Component<Props, State> {
    componentDidMount() {
        if (this.props.hamburgerState.subMenuState && !this.props.hamburgerState.lightHamburgerState) {
            this.triggerDisplay()
        }
    }

    componentDidUpdate(oldProps: Props) {
        if (!oldProps.hamburgerState.subMenuState && this.props.hamburgerState.subMenuState && !this.props.hamburgerState.lightHamburgerState) {
            this.triggerDisplay()
        }
    }

    triggerDisplay() {
        this.props.hamburgerState.triggerLightHamburger()
    }

    renderLink(link: SubNavLink) {

        const onClick = () => {
            if (link.action) {
                link.action()
            }

            this.props.hamburgerState.setSubMenuState(false)
        }

        if (link.link === undefined || link.link.includes("http")) {
            return <a
                key={link.link || link.name}
                onClick={onClick}
                className={styles.SubNav__listLink}
                href={link.link ? link.link : "#"}>
                {link.name}
            </a>
        }

        return (
            <NavLink
                key={link.link}
                onClick={onClick}
                className={({ isActive }) => isActive ? `${styles.SubNav__listLink__active} ${styles.SubNav__listLink}` : styles.SubNav__listLink}
                to={link.link}>

                {link.name}
            </NavLink>
        )
    }

    render() {

        return (
            <div className={`${styles.SubNav} ${this.props.hamburgerState.subMenuState && styles.SubNav__mobileOpen}`}>
                <h2 className={styles.SubNav__title}>{this.props.title}</h2>
                <ul className={styles.SubNav__list}>
                    {this.props.links.map(link => this.renderLink(link))}
                </ul>
            </div>
        )
    }
}

export default SubNav
