import React, { Component, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import GeneralPage from '../components/General/GeneralPage/GeneralPage'
import { OrderFilterInput, OrderSortInput, useTripsOrderDashboardQuery, TripsOrderDashboardQueryVariables, InnerOrderFragment, OrderStatus, useTripsUpdateOrderMutation, TripsUpdateOrderMutationVariables, SortEnumType } from '../generated/graphql'
import Button, { ButtonType } from '../components/General/Button/Button'
import Tile from '../components/General/Tile/Tile'
import GridBase, { GridOptions, GridSize } from '../components/General/GridBase/GridBase'
import moment from 'moment'
import PaginationWrapper from '../components/General/Pagination/PaginationWrapper'
import Modal from '../components/Modal/Modal'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import HookedTextInput from '../components/General/Inputs/HookedTextInput'
import propName from '../helpers/propName'
import HookedTextArea from '../components/General/Inputs/HookedTextArea'

export const OrdersFragment = gql`
    fragment innerOrder on Order {
        orderKey
        location {
            name
            city
            state
        }
        customer {
            name
        }
        supplier {
            name
        }
        terminal {
            name
        }
        carrier {
            carrierKey
            name
        }
        deliveryDate
        tripNumber
        status
        actualGallons
        gallons
        manifest
    }

    fragment orderTripsDashboardListing on OrderGetForTripsDashboardConnection {
        edges {
            cursor
            ...innerOrderListing
        }
        totalCount
    }

    fragment innerOrderListing on OrderGetForTripsDashboardEdge {
        node {
            ...innerOrder
        }
    }

    query tripsOrderDashboard($sort: [OrderSortInput!], $filter: OrderFilterInput, $cursor: String!, $pageSize: Int!) {
        orderGetForTripsDashboard(order: $sort, where: $filter, after: $cursor, first: $pageSize) {
            ...orderTripsDashboardListing 
        }
    }
`

export const UpdateOrderTripsMutation = gql`
    mutation TripsUpdateOrder($orderKey: Int!, $manifest: String, $actualGallons: Int!, $notes: String) {
        orderTripsUpdate(orderKey: $orderKey, manifest: $manifest, actualGallons: $actualGallons, notes: $notes) 
    }
`

interface OrderDataWrapperProps {
    currentSort: OrderSortInput
    updateSort(newSort: OrderSortInput)

    currentFilter: OrderFilterInput
    updateFilter(newFilter: OrderFilterInput)
}

const OrderDataWrapper = (props: OrderDataWrapperProps): JSX.Element => {
    const pageSize = 25
    const [cursor, setCursor] = useState("LTE=")

    const [order, setOrder] = useState(undefined)

    useEffect(() => {
        setCursor("LTE=")
    }, [props.currentFilter, setCursor])

    const variables: TripsOrderDashboardQueryVariables = {
        cursor: cursor,
        pageSize: pageSize,
        sort: props.currentSort,
        filter: props.currentFilter
    }
    const { data, loading, error, refetch } = useTripsOrderDashboardQuery({ variables })

    const pageChange = (currentCursor: string) => {
        setCursor(currentCursor)
    }

    const [orderUpdate] = useTripsUpdateOrderMutation()
    const submit = (mutationVariables: TripsUpdateOrderMutationVariables) => {
        mutationVariables.orderKey = order.orderKey

        orderUpdate({ variables: mutationVariables })
            .then(_ => refetch())
            .then(_ => setOrder(undefined))
    }

    const validationSchema = yup.object().shape({
        actualGallons: yup.number().required(),
        manifest: yup.string().required(),
        notes: yup.string().notRequired()
    })

    const { register, handleSubmit, errors } = useForm<TripsUpdateOrderMutationVariables>({ validationSchema })
    const commonProps = { register, errors }
    const onSubmit = handleSubmit(submit)

    const gridOptions: GridOptions<InnerOrderFragment> = {
        columnDefs: [
            { headerName: "Trip #", dataTransform: o => o.tripNumber },
            { headerName: "Customer", dataTransform: o => `${o.customer.name} - ${o.location.name ? o.location.name : `${o.location.city}, ${o.location.state}`}` },
            { headerName: "Supplier", dataTransform: o => o.supplier.name },
            { headerName: "Terminal", dataTransform: o => o.terminal.name },
            { headerName: "Delivery Date", dataTransform: o => moment.utc(o.deliveryDate).format('LL'), field: "deliveryDate", sortable: true },
            { headerName: "Est Gallons", dataTransform: o => o.gallons.toString() }
        ],
        isErrored: !!error,
        isLoading: loading,
        rowData: ((loading || error) && !data) ? [] : data.orderGetForTripsDashboard.edges.map(x => x.node),
        dataKeyColumn: "orderKey",
        currentSort: props.currentSort,
        sortAction: props.updateSort,
        currentFilter: props.currentFilter,
        filterAction: props.updateFilter,
        clickAction: setOrder,
        size: GridSize.small
    }

    const goToPdfView = (orderKey: number) => {
        let anchor = document.createElement("a")
        document.body.appendChild(anchor)
        let file = window.location.origin + `/api/orderExport?orderKeys=${orderKey}`

        const token = localStorage.getItem('token')

        let headers = new Headers()
        headers.append('Authorization', `Bearer ${token}`)

        fetch(file, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby)

                window.open(objectUrl)
                window.URL.revokeObjectURL(objectUrl)
            })
    }

    return (
        <PaginationWrapper
            pageSize={pageSize}
            totalCount={data ? data.orderGetForTripsDashboard.totalCount : 0}
            changeCursor={pageChange}>
            <div className="row">
                <div className="col-md-12">
                    <GridBase<InnerOrderFragment> gridOptions={gridOptions} />
                    {!!order && <Modal headerText="Update Order"
                        footerLeftContent={<Button clickAction={_ => setOrder(undefined)} buttonType={ButtonType.Transparent}>Cancel</Button>}
                        footerRightContent={<><Button clickAction={() => goToPdfView(order.orderKey)} buttonType={ButtonType.Secondary}>View Order Dispatch</Button><Button clickAction={onSubmit} buttonType={ButtonType.Primary}>Update</Button></>}>
                        <form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    Delivery Date: <p>{moment.utc(order.deliveryDate).format('LL')}</p>
                                </div>
                                <div className="col-md-6">
                                    Trip #: <p>{order.tripNumber}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <HookedTextInput
                                        propertyKey={propName<TripsUpdateOrderMutationVariables>(o => o.manifest)}
                                        label="Manifest"
                                        {...commonProps}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <HookedTextInput
                                        propertyKey={propName<TripsUpdateOrderMutationVariables>(o => o.actualGallons)}
                                        label="Actual Gallons"
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <HookedTextArea
                                        propertyKey={propName<TripsUpdateOrderMutationVariables>(o => o.notes)}
                                        label="Notes"
                                        {...commonProps}
                                    />
                                </div>
                            </div>
                        </form>
                    </Modal>}
                </div>
            </div>
        </PaginationWrapper>
    )
}

interface Props {

}

interface State {
    currentSort: OrderSortInput
    currentFilter: OrderFilterInput
}


export default class Home extends Component<Props, State> {
    constructor(props) {
        super(props)

        const currentSort: OrderSortInput = { deliveryDate: SortEnumType.Asc }
        const currentFilter: OrderFilterInput = { status: { eq: OrderStatus.Dispatched }, tripManifest: { eq: null } }

        this.state = {
            currentSort,
            currentFilter
        }
    }

    sortClick(currentSort: OrderSortInput) {
        this.setState({ currentSort })
    }

    filterClick(currentFilter: OrderFilterInput) {
        let currentSort = Object.assign({}, this.state.currentSort)

        this.setState({ currentFilter, currentSort })
    }

    render() {
        return (
            <GeneralPage title="Orders">
                <Tile>
                    <OrderDataWrapper
                        currentSort={this.state.currentSort}
                        updateSort={this.sortClick.bind(this)}
                        currentFilter={this.state.currentFilter}
                        updateFilter={this.filterClick.bind(this)}
                    />
                </Tile>
            </GeneralPage>
        )
    }
}