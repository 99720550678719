import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'bootstrap/dist/css/bootstrap.min.css'

import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    HttpLink,
    DefaultOptions,
    ApolloLink
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { refreshToken, isTokenValid } from './helpers/loginHelpers'


const tokenRefreshLink = new TokenRefreshLink({
    accessTokenField: 'token',
    isTokenValidOrUndefined: () => {
        const token = localStorage.getItem('token')
        return !!token ? isTokenValid(token) : undefined
    },
    fetchAccessToken: () => {
        return refreshToken({
            token: localStorage.getItem('token'),
            refreshToken: localStorage.getItem('refreshToken')
        })

    },
    handleResponse: () => {
        return (response: Response) => {

            if (response.status !== 200) {
                localStorage.removeItem('token')
                localStorage.removeItem('refreshToken')
                return response
            }


            return response.json()
                .then(data => {
                    localStorage.setItem('refreshToken', data.refreshToken)
                    return data
                })
        }
    },
    handleFetch: (token) => {
        localStorage.setItem('token', token)
    },
    handleError: (err) => {
        window.location.href = `${window.location.origin}/login`
    }
})

const httpLink = new HttpLink({
    uri: `${window.location.origin}/graphql`,
    credentials: 'same-origin'
})

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token')
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    }
})

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache"
    },
    query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
    }
}

const client = new ApolloClient({

    link: ApolloLink.from([
        tokenRefreshLink,
        authLink,
        httpLink
    ]),

    cache: new InMemoryCache(),
    defaultOptions
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
