import React from 'react'
import styles from './GeneralPage.module.scss'
import Sidebar from '../Sidebar/Sidebar'


interface Props {
    title?: string
    beforeChildren?: React.ReactNode
    afterChildren?: React.ReactNode
    headerContent?: React.ReactNode

    centerText?: string
    centerContent?: React.ReactNode

    hideHeader?: boolean
    hasFooter?: boolean
    showSidebar?: boolean

    sidebarContent?: React.ReactNode

    children?: React.ReactNode
}

const GeneralPage: React.FC<Props> = ({ title, children, beforeChildren, afterChildren, headerContent, centerText, centerContent, hideHeader, hasFooter, showSidebar, sidebarContent }) => {
    document.title = title
    return (
        <div className={`${styles.GeneralPage}`}>
            <div className={styles.GeneralPage__bodyContainer}>
                {beforeChildren}
                <div className={`${styles.GeneralPage__mainSection} ${hasFooter ? styles.GeneralPage__hasFooter : ""} gen-container`}>
                    {!hideHeader && <div className={styles.GeneralPage__titleSection}>
                        {title && <h2 className={styles.GeneralPage__title}>{title}</h2>}
                        <div className={styles.GeneralPage__middleSection}>
                            {centerText && <h3 className={styles.GeneralPage__centerText}>{centerText}</h3>}
                            {centerContent}
                        </div>
                        <div className={styles.GeneralPage__headerContent}>
                            {headerContent}
                        </div>
                    </div>}
                    {children}
                </div>

                {afterChildren}
            </div>
            {showSidebar && <Sidebar content={sidebarContent}></Sidebar>}
        </div>
    )
}

export default GeneralPage
