import { JwtPayload, jwtDecode } from "jwt-decode"


const isTokenValid = (token: string) => {
    if(!token) { return false }

    const now = Date.now().valueOf() / 1000
    const jwtExpirationTime = jwtDecode<JwtPayload>(token).exp
    return now < jwtExpirationTime
}

export interface RefreshBody {
    token: string
    refreshToken: string
}

export interface UserLogin {
    UserName: string
    Password: string
}

export interface LoginResponse {
    token?: string
    refreshToken?: string
    expiration?: string
    statusText?: string
    statusCode?: number
}

const refreshToken = (refreshBody: RefreshBody) => {

    return fetch('/api/authenticate/refresh', {
        method: 'POST',
        body: JSON.stringify(refreshBody),
        headers: {
            'Content-Type': 'application/json'
        }
    })

}

const login = (userLogin: UserLogin): Promise<LoginResponse> => {
    return fetch("/api/authenticate/login", {
        method: 'POST',
        body: JSON.stringify(userLogin),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(data => {
        const { status: statusCode , statusText } = data
        return statusCode === 200 ? data.json() : { statusText, statusCode }
    });
}



export {
    isTokenValid,
    refreshToken,
    login
}