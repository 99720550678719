import React, { ReactElement, useState } from 'react'
import GeneralPage from '../../components/General/GeneralPage/GeneralPage'
import useForm from 'react-hook-form'
import HookedTextInput from '../../components/General/Inputs/HookedTextInput'
import propName from '../../helpers/propName'
import * as yup from "yup"
import Tile from '../../components/General/Tile/Tile'
import Button from '../../components/General/Button/Button'
import { GlobalLoginProps } from '../../App'
import { UserLogin } from '../../helpers/loginHelpers'
import { Navigate, useSearchParams } from 'react-router-dom'

interface Props extends GlobalLoginProps {

}


export default function Login({ isAuthenticated, login }: Props): ReactElement {
    const [errorMessage, setErrorMessage] = useState<string>(null)
    let [searchParams,] = useSearchParams()

    const submit = (userLogin: UserLogin) => {

        login(userLogin)
            .then(data => {
                if (data.token) { return }

                switch (data.statusText) {
                    case "Unauthorized":
                        setErrorMessage("Incorrect Username or Password.")
                        break
                    default:
                        setErrorMessage(data.statusText)
                        break
                }

            })
    }

    const validationSchema = yup.object().shape({
        UserName: yup.string().required().trim(),
        Password: yup.string().required()
    })

    const { register, handleSubmit, errors } = useForm<UserLogin>({ validationSchema })

    const onSubmit = handleSubmit(submit)

    const fromParam = searchParams.get("from")
    const onAuthenticatePath = fromParam ? { pathname: fromParam } : { pathname: '/' }

    if (isAuthenticated) {
        return <Navigate to={onAuthenticatePath} replace />
    }

    return (
        <GeneralPage title="Gas Point" centerContent={
            <h3>Smith Gas Liquids</h3>
        }>

            <div className="row justify-content-md-center">
                <div className="col col-md-5">
                    <Tile>
                        <form onSubmit={onSubmit}>

                            <HookedTextInput
                                register={register}
                                label="Email"
                                propertyKey={propName<UserLogin>(o => o.UserName)}
                                errors={errors}
                            />

                            <HookedTextInput
                                register={register}
                                isPassword={true}
                                label="Password"
                                propertyKey={propName<UserLogin>(o => o.Password)}
                                errors={errors}
                            />

                            <p>{errorMessage}</p>


                            <Button isSubmit={true} submitValue="Login" />
                            {/* <input type="submit" value="Login"/> */}

                        </form>
                    </Tile>
                </div>
            </div>

        </GeneralPage>
    )
}
