import React from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js'

interface Props {
    instrumentationKey: string
    after: (() => void)
    children?: React.ReactNode
}

export const TelemetryProvider = (props: Props) => {
    const reactPlugin = new ReactPlugin()
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: props.instrumentationKey,
            extensions: [reactPlugin],
            enableAutoRouteTracking: true
        }
    })
    appInsights.loadAppInsights()

    return <AppInsightsContext.Provider value={reactPlugin}>
        {props.children}
    </AppInsightsContext.Provider>
}